import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setCurrentPropertySlug, } from 'actions/condoActions'
import { inviteUser } from 'actions/properties'
import { getCurrentProperty } from 'selectors/properties'
import { getCurrentCondo } from 'selectors/condos'

import Page from 'components/Page'

import Firebind from 'components/Firebind'
import Vehicles from 'components/Properties/Vehicles'
import Contacts from 'components/Properties/Contacts'
import InviteUser from './InviteUser'
import { Row, Col } from 'react-flexbox-grid'
import {
  Avatar,
  List,
  ListItem,
} from 'material-ui'
import PersonAddIcon from 'material-ui-icons/PersonAdd'
import PropertyDetailForm from './PropertyDetailForm'

import IsCondoAdmin, { withCondoAdmin } from 'utils/IsCondoAdmin'
import Panel from '../Panel'
import DialogButton from '../DialogButton'
import UserItem from './UserItem'


const Invitations = ({firebindData}) => {
  const invitationsArray = Object.keys(firebindData)
    .map(key => ({
      key,
      ...firebindData[key]
    }))

  return (
    <div>
      {invitationsArray
        .filter(invite => invite.status !== 'accept')
        .map(invite => <UserItem key={invite.key} type="invite" firebindData={invite} />)}
    </div>
  )
}

const PropertyDetail = ({ title, path, na = <i>N/A</i> }) => (
  <div style={{ marginBottom: '0.5rem' }}>
    <dt><strong>{title}</strong></dt>
    <dd style={{ marginLeft: 0 }}><Firebind path={path} notFound={na} /></dd>
  </div>
)

export const Details = withCondoAdmin(({ isCondoAdmin, path }) => (
  <Panel md={8} heading={<FormattedMessage id="property_details" />}>
    {!isCondoAdmin && (
      <dl>
        <PropertyDetail
          title={<FormattedMessage id="property_id" />}
          path={`${path}/propertyId`}
          canEdit={isCondoAdmin} />

        <PropertyDetail
          title={<FormattedMessage id="property_registry" />}
          path={`${path}/registry`}
          canEdit={isCondoAdmin} />

        <PropertyDetail
          title={<FormattedMessage id="property_registry_id" />}
          path={`${path}/registryId`}
          canEdit={isCondoAdmin} />
      </dl>
    )}
    {isCondoAdmin && (
      <Firebind
        path={path}
        render={({ propertyId, registry, registryId }) => (
          <PropertyDetailForm data={{ propertyId, registry, registryId }} path={path} />
        )} />
    )}
  </Panel>
))

class PropertyDetailsContainer extends React.Component {
  componentDidMount() {
    if (this.props.match.params.propertyId) {
      this.props.setCurrentPropertySlug(this.props.match.params.propertyId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params && nextProps.params &&
      this.props.params.propertyId !== nextProps.params.propertyId) {
      nextProps.setCurrentPropertySlug(nextProps.params.propertyId)
    }
  }

  componentWillUnmount() {
    const { setCurrentPropertySlug } = this.props
    setCurrentPropertySlug(null)
  }

  render() {
    const {
      currentProperty,
      currentCondo,
      inviteUser,
    } = this.props

    if (!currentProperty || !currentCondo) {
      return null
    }

    const propertyPath = `/properties/${currentCondo.key}/${currentProperty.key}`

    return (
      <Page title={<span><FormattedMessage id="property"/> <Firebind path={`${propertyPath}/name`} /></span>}>
        <Row style={{ alignItems: 'center' }}>
          <Col xs={10}>
            <p style={{ fontWeight: 200 }}><FormattedHTMLMessage id="accept_tos" /></p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Details path={propertyPath}/>
            <Panel md={8} heading={<FormattedMessage id="property_users" />}>
              <List>
                {currentProperty.users && Object.keys(currentProperty.users).map( userId => (
                  <Firebind
                    key={userId}
                    path={`/users/${userId}`}
                    notFound={<small>{`User with ID: ${userId} was not found`}</small>}>
                    <UserItem property={currentProperty} condo={currentCondo} />
                  </Firebind>
                ))}
                <Firebind
                  bindType="on"
                  path={`/invitations/${currentCondo.key}/${currentProperty.key}`}>
                  <Invitations />
                </Firebind>
                <IsCondoAdmin>
                  <DialogButton
                    title={<div><FormattedMessage id="invite_user_to_manage_property" /></div>}
                    launchButton={(
                    <ListItem
                      leftAvatar={<Avatar style={{ marginRight: '1rem' }} icon={<PersonAddIcon />} />}
                      primaryText={<FormattedMessage id="invite_user_to_manage_property" />}
                      />
                    )}>
                    {/* Form should allow user to add current condo user or new user altogether */}
                    <InviteUser
                      condo={currentCondo}
                      property={currentProperty}
                      inviteUser={inviteUser}/>
                  </DialogButton>
                </IsCondoAdmin>
              </List>
            </Panel>
            <Contacts condo={currentCondo} property={currentProperty} />
            <Vehicles condo={currentCondo} property={currentProperty} />
          </Col>
        </Row>
      </Page>
    )
  }
}

export const PropertyDetails = connect(
  state => ({
    currentCondo: getCurrentCondo(state),
    currentProperty: getCurrentProperty(state),
  }),
  dispatch => ({
    setCurrentPropertySlug: bindActionCreators(setCurrentPropertySlug, dispatch),
    inviteUser: bindActionCreators(inviteUser, dispatch),
  })
)(PropertyDetailsContainer)
