import React from 'react'
import { withCondo, withRedux } from 'utils'
import { Link } from 'react-router-dom'
import { FormattedMessage, FormattedDate } from 'react-intl'
import { getBalances } from 'selectors/properties'
import Loading from 'components/Loading'
import Firebind from 'components/Firebind'
import Currency from 'components/Currency'
import _map from 'lodash/map'

import { Grid, Row } from 'react-flexbox-grid'
import Panel from 'components/Panel'
import Typography from 'components/Typography'

const PropertyName = ({ firebindData }) => <span>{firebindData.name}</span>

const Statements = ({ data, condo }) => {
  if (data === 'loading') {
    return <Loading />
  }

  if (!data.balances) return <FormattedMessage id="no_info" />

  return (
    <Grid>
      <Row>
        {_map(data.balances, balance => (
          <Link
            to={`/condo/${condo.slug}/statement/${balance.propertyId}`}
            key={balance.propertyId}
          >
            <Panel
              md={3}
              heading={<FormattedMessage id="balance" />}
              style={{ marginRight: '1rem' }}
            >
              <strong>
                <FormattedMessage id="property" />:
              </strong>{' '}
              <Firebind
                path={`/properties/${balance.condoId}/${balance.propertyId}`}
              >
                <PropertyName />
              </Firebind>
              {data !== 'loading' && (
                <Typography type="subheading">
                  <FormattedMessage id="balance" />:{' '}
                  <Currency value={balance.balance} />
                </Typography>
              )}
              <p>
                <small>
                  <FormattedMessage id="last_updated" />:{' '}
                  <FormattedDate value={balance.updated} />
                </small>
              </p>
            </Panel>
          </Link>
        ))}
      </Row>
    </Grid>
  )
}

export default withRedux({
  data: getBalances
})(withCondo(Statements))
