import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'

import InputField from 'components/InputField'
import RaisedButton from 'material-ui/RaisedButton'

const style = {
  buttons: { margin: 5 },
}

const ContactForm = ({
    handleSubmit,
    onSubmit,
    submitting,
    initialValues: { key },
    intl: { formatMessage },
  }) => (
  <form onSubmit={handleSubmit} className="editor">
    <div>
      <InputField
        name="name"
        label={<FormattedMessage id="contact_name" />}
        required={true}
        />
    </div>
    <div>
      <InputField
        name="identification"
        label={<FormattedMessage id="contact_identification" />}
        />
    </div>
    <div>
      <InputField
        name="email"
        label={<FormattedMessage id="contact_email" />}
        required={true}
        />
    </div>
    <div>
      <InputField
        name="phone"
        label={<FormattedMessage id="contact_phone" />}
        />
    </div>
    <RaisedButton style={style.buttons} type="submit" disabled={submitting} primary={true}>
      <FormattedMessage id="save" />
    </RaisedButton>
    {key && (<RaisedButton style={style.buttons} type="button" disabled={submitting} secondary={true} buttonStyle={{ color: '#FFFFFF' }}
      onClick={_ => {
        handleSubmit(values => {
          onSubmit({
            ...values,
            action: 'delete',
          })
        })
      }}>
      <FormattedMessage id="delete" />
    </RaisedButton>)}
  </form>
)

export default reduxForm({
  form: 'ContactForm',
})(injectIntl(ContactForm))
