import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  IconButton,
  Dialog,
  FlatButton,
} from 'material-ui'

import MoreIcon from 'material-ui-icons/MoreVert'

export default class DialogButton extends React.Component {
  state = {
    open: false,
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  render() {
    const {
      actionButton = null,
      cancelText = <FormattedMessage id="cancel" />,
      children,
      launchButton = <IconButton><MoreIcon /></IconButton>,
      title,
    } = this.props

    const LaunchButton = launchButton

    const { open } = this.state

    const childWithClose = React.cloneElement(children, {
      handleClose: this.handleClose,
    })

    return (
      <div>
        {React.cloneElement(LaunchButton, {
          onClick: this.handleOpen,
        })}
        <Dialog
          open={open}
          onRequestClose={this.handleClose}
          title={title}
          actions={
            <div>
              <FlatButton
              onClick={this.handleClose}
              color="primary">{cancelText}</FlatButton>

              {actionButton !== null && actionButton}
            </div>}
          >
            {childWithClose}
        </Dialog>
      </div>
    )
  }
}
