import React from 'react'
import { FormattedMessage } from 'react-intl'
import SignUpContainer from './../SignUp'
import Panel from 'components/Panel'

import {
  RaisedButton
} from 'material-ui'

import { Grid, Row, Col } from 'react-flexbox-grid'

export const AcceptInviteMessage = ({ user, invite, acceptInvitation, disableButton}) => (
  <Panel>
    <h3><FormattedMessage id="email_invite_header" /></h3>
    <p>
      <FormattedMessage id="email_invite_copy" values={{ condo: invite.condoName || 'Condo' }} />
    </p>
    {!user && <p><strong style={{ color: '#DD0000' }}><FormattedMessage id="email_invite_login" /></strong></p>}
    <div>
      {user && <RaisedButton disabled={disableButton} primary={true} fullWidth={true} onClick={acceptInvitation}><FormattedMessage id="email_invite_button" /></RaisedButton>}
    </div>
  </Panel>
)

const InviteForm = ({user, invite, acceptInvitation, disableButton}) =>
  <Grid>
    <Row center="xs">
      <Col xs={8}>
        <AcceptInviteMessage disableButton={disableButton} user={user} invite={invite} acceptInvitation={acceptInvitation} />
      </Col>
      {!user && (<Col xs={4} style={{ textAlign: 'left'}}><SignUpContainer /></Col>)}
    </Row>
  </Grid>
export default InviteForm
