import React from 'react'
import classNames from 'classnames'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const styles = theme => ({
  root: {
    display: 'block',
    margin: 0,
  },
  display4: theme.typography.display4,
  display3: theme.typography.display3,
  display2: theme.typography.display2,
  display1: theme.typography.display1,
  headline: theme.typography.headline,
  title: theme.typography.title,
  subheading: theme.typography.subheading,
  body2: theme.typography.body2,
  body1: theme.typography.body1,
  caption: theme.typography.caption,
  button: theme.typography.button,
  label: theme.typography.label,
  alignLeft: {
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignJustify: {
    textAlign: 'justify',
  },
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  gutterBottom: {
    marginBottom: '0.35em',
  },
  paragraph: {
    marginBottom: theme.spacing.unit * 2,
  },
  colorInherit: {
    color: 'inherit',
  },
  colorSecondary: {
    // color: theme.palette.text.secondary,
  },
  colorAccent: {
    // color: theme.palette.accent.A400,
  },
})

function Typography(props) {
  const {
    align,
    classes = {},
    className: classNameProp,
    component: componentProp,
    color,
    gutterBottom,
    headlineMapping,
    noWrap,
    paragraph,
    type: typeProp,
    ...other
  } = props


  // workaround: see https://github.com/facebook/flow/issues/1660#issuecomment-297775427
  const type = typeProp || Typography.defaultProps.type

  const className = classNames(
    classes.root,
    classes[type],
    {
      [classes[`color${capitalizeFirstLetter(color)}`]]: color !== 'default',
      [classes.noWrap]: noWrap,
      [classes.gutterBottom]: gutterBottom,
      [classes.paragraph]: paragraph,
      [classes[`align${capitalizeFirstLetter(align)}`]]: align !== 'inherit',
    },
    classNameProp,
  )

  const Component = componentProp || (paragraph ? 'p' : headlineMapping[type]) || 'span'

  return <Component className={className} {...other} />
}

Typography.defaultProps = {
  align: 'inherit',
  color: 'default',
  gutterBottom: false,
  headlineMapping: {
    display4: 'h1',
    display3: 'h1',
    display2: 'h1',
    display1: 'h1',
    headline: 'h1',
    title: 'h2',
    subheading: 'h3',
    body2: 'aside',
    body1: 'p',
    label: 'label',
  },
  noWrap: false,
  paragraph: false,
  type: 'body1',
}

export default Typography
