const initialState = {
  drawerOpen: false,
}

const appReducer = (state = initialState, action)=> {
  switch (action.type) {
    case "APP_DRAWER":
      return {
        ...state,
        drawerOpen: action.open,
      }
    default:
      return state
  }
}

export default appReducer
