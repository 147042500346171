import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Switch, Link, Route, Redirect } from 'react-router-dom'
// import { RouteTransition } from 'react-router-transition'

import { AppBar, Drawer, IconButton, ListItem, List } from 'material-ui'

import IconClose from 'material-ui-icons/Close'
import IconMenu from 'material-ui-icons/Menu'
import IconHome from 'material-ui-icons/Home'
import IconUser from 'material-ui-icons/Person'

import PrivateRoute from 'utils/PrivateRoute'
import CondoPicker from 'components/Condos/CondoPicker'
import ToggleDrawer from 'components/ToggleDrawer'
import Logout from 'components/Logout'
import AppBarTitle from 'components/AppBarTitle'
import CondoMenu from 'components/Condos/CondoMenu'
import Profile from 'components/Profile'
import Logo from 'components/Logo'
import PropertyInit from 'components/Properties/Init'
import PropertyBalance from 'components/PropertyBalance'
import IsCondoAdmin from 'utils/IsCondoAdmin'

import Typography from 'components/Typography'

import Dashboard from './Dashboard'
import CondoDashboard from './Dashboard/CondoDashboard'

import { init as initCondos } from 'actions/condoActions'
import { stylesheet } from 'styles'

// <div style={{ display: 'flex', alignItems: 'center' }}>
//   <IconHome />
//   <span style={{ paddingLeft: '10px' }}><FormattedMessage id="home" /></span>
// </div>

const AppBarMenu = ({ children }) => (
  <div style={stylesheet.appBarMenu}>{children}</div>
)

const AppBarActions = ({ children }) => (
  <div style={stylesheet.appBarActions}>{children}</div>
)

class AppLayout extends React.Component {
  componentDidMount() {
    this.props.initCondos()
  }

  render() {
    const {
      drawerOpen,
      condos,
      location: { pathname }
    } = this.props

    // If the user only has one condo, then send them to that home page
    if (
      pathname === '/' &&
      condos &&
      condos.condos &&
      condos.condos.length === 1
    ) {
      return <Redirect to={`/condo/${condos.condos[0].slug}`} />
    }

    return (
      <div>
        <AppBar
          color="default"
          height={100}
          className="micondo-appbar"
          iconElementLeft={
            <ToggleDrawer>
              <IconMenu color="#FFFFFF" />
            </ToggleDrawer>
          }
          title={<AppBarTitle />}
        >
          <Logo
            inverse={true}
            style={{
              position: 'absolute',
              top: '1rem',
              left: '62px',
              height: '2rem',
              width: 'auto'
            }}
          />
          <Link to="/profile">
            <IconButton>
              <IconUser color="#FFFFFF" />
            </IconButton>
          </Link>
          <Logout color="#FFFFFF" />
          <AppBarMenu>
            <Route path="/condo/:condoId" component={CondoMenu} />
          </AppBarMenu>
          <AppBarActions>
            <IsCondoAdmin
              otherwise={
                <PropertyBalance
                  linkTo={`/condo/${condos.condos.length &&
                    condos.condos[0].slug}/statement`}
                />
              }
            />
          </AppBarActions>
        </AppBar>
        <Drawer open={drawerOpen}>
          <AppBar
            color="#FFF000"
            style={{ height: '50px' }}
            titleStyle={{ height: '50px' }}
            iconStyleLeft={{ marginTop: '2px' }}
            iconElementLeft={
              <ToggleDrawer>
                <IconClose color="#FFFFFF" />
              </ToggleDrawer>
            }
          />
          <Link to="/dashboard">
            <ToggleDrawer wrapper={List}>
              <ListItem
                primaryText={<FormattedMessage id="home" />}
                leftIcon={<IconHome />}
              />
            </ToggleDrawer>
          </Link>
          <Logout menuItem={true} />
          <CondoPicker wrapper={ToggleDrawer} />
          <Route
            path="/condo/:condoId"
            render={() => (
              <div>
                <div style={stylesheet.menuLabel}>
                  <Typography align="right" type="display">
                    <FormattedMessage id="menu" />
                  </Typography>
                </div>
                <ToggleDrawer wrapper={List}>
                  <CondoMenu
                    isLeftMenu={true}
                    renderChildren={(children, to) => (
                      <Link to={to}>
                        <ListItem primaryText={children} />
                      </Link>
                    )}
                  />
                </ToggleDrawer>
              </div>
            )}
          />
        </Drawer>
        <div id="body">
          {/* <RouteTransition
            pathname={location.pathname}
            atEnter={{translateX: 100}}
            atLeave={{translateX: -100}}
            atActive={{translateX: 0}}
            mapStyles={styles => ({ transform: `translateX(${styles.translateX}%)`})}
            > */}
          <Switch>
            <PrivateRoute
              path="/init/:condoId/:propertyId"
              component={PropertyInit}
            />
            <PrivateRoute path="/condo/:condoId" component={CondoDashboard} />
            <PrivateRoute
              exact
              path="/dashboard"
              component={Dashboard}
              permission="/accounts/{uid}"
            />
            <PrivateRoute exact path="/profile" component={Profile} />
          </Switch>
          {/* </RouteTransition> */}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    drawerOpen: state.app.drawerOpen,
    condos: state.condos
  }),
  dispatch => ({
    initCondos: bindActionCreators(initCondos, dispatch)
  })
)(AppLayout)
