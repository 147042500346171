import React from 'react'

import { Paper, Toolbar } from 'material-ui'
import { stylesheet } from 'styles'
import Typography from 'components/Typography'
import './css/panel.css'

const _padding = '1.5rem'

export default ({
  button,
  children,
  disabled = false,
  heading,
  headingRight,
  padding = _padding,
  style = {},
  titleType = 'subheading',
  ...props
}) => {
  return (
    <div style={stylesheet.panel.container} className="micondo-panel">
      <div className={`micondo-panel-disabled ${disabled ? 'show' : ''}`} />
      <Paper style={style}>
        {heading && (
          <Toolbar style={stylesheet.panel.toolbar}>
            <Typography type={titleType} style={stylesheet.panel.heading}>
              {heading}
            </Typography>
            {headingRight}
            {button && <div style={stylesheet.panel.buttons}>{button}</div>}
          </Toolbar>
        )}
        <div
          style={
            heading
              ? { padding: `0 ${padding} ${padding}` }
              : { padding: `${padding}` }
          }
        >
          {children}
        </div>
      </Paper>
    </div>
  )
}
