import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { saveVehicle, deleteItem } from 'actions/properties'
import { bindFirebasePath, unbindFirebasePath } from 'actions/fbActions'
import { withCondoAdmin } from 'utils/IsCondoAdmin'
// import { getCurrentCondo } from 'selectors/condos'
// import { getCurrentProperty } from 'selectors/properties'
//
import Loading from 'components/Loading'
import Panel from 'components/Panel'


import VehicleForm from 'components/Properties/VehicleForm'

import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableRowColumn,
  // Grid,
} from 'material-ui'

import MotorcycleIcon from 'material-ui-icons/Motorcycle'
import CarIcon from 'material-ui-icons/DirectionsCar'
import EditIcon from 'material-ui-icons/Edit'
import IconDelete from 'material-ui-icons/Delete'

class VehiclesContainer extends React.Component {
  state = {
    current: null,
    showForm: false,
    saving: false,
  }

  bindVehicles(props = this.props) {
    const { condo, bind, property } = props

    if (!condo) {
      return
    }

    bind(`/properties/${condo.key}/${property.key}/vehicles?asArray=true`, 'vehicles', true)
  }

  componentWillReceiveProps(nextProps) {
    const {
      condo,
      property,
      unbind,
    } = this.props

    const {
      condo: newCondo,
      property: newProperty,
    } = nextProps

    if (!newCondo || !newProperty) {
      return
    }

    if ((condo && condo.key !== newCondo.key) ||
      (property && property.key !== newProperty.key)) {
        unbind(null, 'vehicles')
    }

    this.bindVehicles(nextProps)

    // See if the form is saving
    const { firebase: { keys: { vehicles: nextVehicles } } } = nextProps
    const { firebase: { keys: { vehicles } } } = this.props

    if (nextVehicles && vehicles && nextVehicles.value && vehicles.value &&nextVehicles.value.length !== vehicles.value.length) {
      this.setState({
        saving: false,
        showForm: false,
      })
    }
  }

  componentDidMount() {
    this.bindVehicles()
  }

  componentWillUnmount() {
    const { unbind } = this.props
    unbind(null, 'vehicles')
  }

  toggleForm = _ => {
    this.setState({
      showForm: !this.state.showForm,
      current: null,
    })
  }

  selectVehicle = current => {
    const { firebase: { keys: { vehicles }}} = this.props

    this.setState({
      showForm: true,
      current: vehicles.value[current],
    })
  }

  onSubmit = vehicle => {
    const { condo, property, saveVehicle } = this.props
    saveVehicle(condo, property, vehicle, this.state.current && this.state.current.key)
    this.setState({ saving: true })
    this.setState({ current: false })
  }

  onDelete = vehicle => {
    const { condo, property, deleteVehicle } = this.props
    deleteVehicle(condo, property, vehicle)
    this.setState({ current: false })
  }

  render() {
    const {
      firebase: { keys: { vehicles } },
      isCondoAdmin,
    } = this.props

    const { current, showForm, saving } = this.state

    if (!vehicles || vehicles.loading) {
      return <Loading />
    }

    const currentVehicle = {
      type: 'car',
    }

    return (
      <Panel
        heading={<FormattedMessage id='property_vehicles' />}
        button={<EditIcon onClick={this.toggleForm} />}>
        {vehicles.value && (
          <VehiclesList
            saving={saving}
            isCondoAdmin={isCondoAdmin}
            data={vehicles.value}
            onDelete={this.onDelete}
            onSelect={this.selectVehicle} />
          )}
        {showForm && <VehicleForm
          onSubmit={this.onSubmit}
          submitting={saving}
          enableReinitialize={true}
          isValid={true}
          initialValues={{ ...currentVehicle, ...current}} />}
      </Panel>
    )
  }
}

const VehiclesList = ({ isCondoAdmin, onDelete, data, onSelect, saving }) => (
  <Table onRowSelection={rows => onSelect(rows[0]) }>
    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
      <TableRow>
        <TableRowColumn style={{ width: '32px' }}><FormattedMessage id='vehicle_type' /></TableRowColumn>
        <TableRowColumn style={{ width: '40px' }}><FormattedMessage id='vehicle_year' /></TableRowColumn>
        <TableRowColumn><FormattedMessage id='vehicle_make' /></TableRowColumn>
        <TableRowColumn><FormattedMessage id='vehicle_model' /></TableRowColumn>
        <TableRowColumn><FormattedMessage id='vehicle_license' /></TableRowColumn>
        <TableRowColumn><FormattedMessage id='vehicle_access' /></TableRowColumn>
        {isCondoAdmin && <TableRowColumn />}
      </TableRow>
    </TableHeader>
    <TableBody displayRowCheckbox={false}>
      {data.map((vehicle, idx) => (
        <TableRow key={idx}>
          <TableRowColumn style={{ width: '32px' }}>
            {vehicle.type === 'motorcycle' && <MotorcycleIcon />}
            {vehicle.type !== 'motorcycle' && <CarIcon />}
          </TableRowColumn>
          <TableRowColumn style={{ width: '40px' }}>{vehicle.year}</TableRowColumn>
          <TableRowColumn>{vehicle.make}</TableRowColumn>
          <TableRowColumn>{vehicle.model}</TableRowColumn>
          <TableRowColumn>{vehicle.license}</TableRowColumn>
          <TableRowColumn>{vehicle.access}</TableRowColumn>
          {isCondoAdmin && (
            <TableRowColumn>
              <IconDelete onClick={() => onDelete(vehicle, idx)} style={{ cursor: 'pointer' }} />
            </TableRowColumn>
          )}
        </TableRow>
      ))}
      {saving && (
        <TableRow>
          <TableRowColumn colSpan={6}><FormattedMessage id="saving" /> ...</TableRowColumn>
        </TableRow>
      )}
    </TableBody>
  </Table>
)

export default connect(
  state => ({
    firebase: state.firebase,
  }),
  dispatch => ({
    saveVehicle: bindActionCreators(saveVehicle, dispatch),
    deleteVehicle: bindActionCreators(deleteItem('vehicles'), dispatch),
    bind: bindActionCreators(bindFirebasePath, dispatch),
    unbind: bindActionCreators(unbindFirebasePath, dispatch),
  })
)(withCondoAdmin(VehiclesContainer))
