import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'

import InputField from 'components/InputField'
import OptionField from 'components/OptionField'

import MenuItem from 'material-ui/MenuItem'
import Checkbox from 'material-ui/Checkbox'
import RaisedButton from 'material-ui/RaisedButton'

const style = {
  buttons: { margin: 5 },
}

const VehicleForm = ({
      handleSubmit,
      onSubmit,
      submitting,
      initialValues: { key },
      intl: { formatMessage },
      helpTextTop = null,
      helpTextBottom = null,
      inset = true,
      requireAll = false,
      validateForm = null,
      isValid = false,
    }) => (
  <form onSubmit={handleSubmit} className={inset ? 'editor' : ''}>
    {requireAll && helpTextTop}
    {requireAll && (
      <div>
        <div>
          <OptionField
            name="type"
            label={<FormattedMessage id="vehicle_type" />}
            required={true}
            >
            <MenuItem value="motorcycle" primaryText={formatMessage({ id: 'vehicle_type_motorcycle' })}/>
            <MenuItem value="car" primaryText={formatMessage({ id: 'vehicle_type_car' })}/>
          </OptionField>
        </div>
        <div>
          <InputField
            name="year"
            type="number"
            label={<FormattedMessage id="vehicle_year" />}
            required={true}
            />
        </div>
        <div>
          <InputField
            name="make"
            label={<FormattedMessage id="vehicle_make" />}
            required={true}
            />
        </div>
        <div>
          <InputField
            name="model"
            label={<FormattedMessage id="vehicle_model" />}
            required={true}
            />
        </div>
        <div>
          <InputField
            name="license"
            label={<FormattedMessage id="vehicle_license" />}
            required={true}
            />
        </div>
        <div>
          <InputField
            name="access"
            label={<FormattedMessage id="vehicle_access" />}
            required={requireAll}
            />
        </div>
      </div>
    )}
    
    {validateForm ? (
      <Field
        name="accept_tos"
        component={(({ input, label }) => (
          <Checkbox
            style={{ margin: '1rem 0' }}
            label={label}
            checked={input.value ? true : false}
            onCheck={input.onChange} />
        ))}
        label={helpTextBottom} />
    ) : helpTextBottom}
    <RaisedButton style={style.buttons} type="submit" disabled={submitting || !isValid} primary={true}>
      <FormattedMessage id="save" />
    </RaisedButton>
    {key && (<RaisedButton style={style.buttons} type="button" disabled={submitting} secondary={true} buttonStyle={{ color: '#FFFFFF' }}
      onClick={_ => {
        handleSubmit(values => {
          onSubmit({
            ...values,
            action: 'delete',
          })
        })
      }}>
      <FormattedMessage id="delete" />
    </RaisedButton>)}
  </form>
)

export default reduxForm({
  form: 'VehicleForm',
})(injectIntl(VehicleForm))
