import { createSelector } from 'reselect'
import { getCurrentCondo } from './condos'

const condos = state => state.condos.condos
const properties = state => state.properties.items
const balances = state => state.properties.balances
const slug = state => state.properties.slug

export const getCurrentProperty = createSelector(
  condos,
  properties,
  slug,
  (condos, items, current) => items && items[current]
)

export const getUnInitializedProperty = createSelector(
  properties,
  getCurrentCondo,
  condos,
  (properties, current) => {
    if (!current) {
      return null
    }
    // If admin, then don't do anything
    if (current && (current._value === 'admin' || current._value === 'owner')) {
      return null
    }

    if (!properties) {
      return null
    }

    const keys = Object.keys(properties)
    if (keys.length === 0) {
      return null
    }

    const found = keys.find(key => !properties[key].init)
    return found && properties[found]
  }
)

export const getBalances = createSelector(
  balances,
  balances => {
    if (!balances) return null

    const _balances = Object.keys(balances).map(key => balances[key])
    const anyPending =
      _balances.find(p => p.status === 'pending') || _balances.length === 0
    if (anyPending) return 'loading'

    return {
      balances,
      balance: _balances.reduce((ret, prop) => ret + prop.balance, 0)
    }
  }
)
