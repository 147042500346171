import React from 'react'
import miCondo from '../assets/miCondo.svg'
import miCondoWhite from '../assets/miCondo-white.svg'

const logo = {
    width: '12rem',
    height: '8rem',
}

const MiCondoLogo = ({inverse, style = {}}) => {
  return (
    <img style={{ ...logo, ...style}} src={inverse ? miCondoWhite : miCondo} alt="MiCondo" />
  )
}

export default MiCondoLogo
