import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import InviteForm from './InviteForm'

import { bindFirebasePath, unbindFirebasePath } from 'actions/fbActions'
import { acceptInvitation } from 'actions/authActions'
import Loading from 'components/Loading'

import { InitRedirect } from 'components/Properties/Init'


class InviteContainer extends React.Component {
  state = {
    accepting: false,
    accepted: false,
    error: null,
  }

  getBindPaths() {
    const {
      match: {
        params: { invite },
      },
    } = this.props

    const [ condoKey, propertyKey, inviteKey ] = invite.split('||')

    return [
      [`/invitations/${condoKey}/${propertyKey}/${inviteKey}`, 'invite', true],
      [`/acceptInvitations`, 'acceptInvitations'],
    ]
  }

  componentDidMount() {
    const [ invite, accepts ] = this.getBindPaths()
    const { bind } = this.props

    bind(...invite)
    bind(...accepts)
  }

  acceptInvitation = () => {
    const {
      auth,
      firebase: { keys: {
        invite,
        acceptInvitations,
      } }
    } = this.props

    this.setState({ accepting: true })

    const inviteKey = invite.ref.key

    acceptInvitations.ref.child(inviteKey).set({
      uid: auth.user.uid,
      ...invite.value
    })
      .then(() => {
        this.setState({
          accepting: false,
          accepted: true,
        })
      })
      .catch(error => {
        this.setState({error})
      })
  }

  componentWillUnMount() {
    const { unbind } = this.props

    const [invite] = this.getBindPaths()
    unbind(...invite)
  }

  render() {
    const {
      auth,
      firebase: {
        keys: { invite }
      },
    } = this.props

    const { accepting, accepted } = this.state

    const stateError = this.state.error

    if (!invite) {
      return <Loading />
    }

    if (stateError) {
      return <h1>{`STATE ERROR: ${stateError} `}</h1>
    }

    const { error, loading, value } = invite

    if (value && value.status === 'accept') {
      return <InitRedirect condo={value.condo} property={value.property} />
    }

    if (error) {
      return <h1><FormattedMessage id="invite_not_found" /></h1>
    }

    // TODO: There are three (maybe four) cases here ...
    // 1. The user is already logged in, in which case he would just have an
    //    accept invitation button
    // 2. The user isn't logged in, in which case he would log in and come
    //    back to this page
    // 3. The user doesn't have an account, in which case he would create one,
    //    then come back to this page
    // 4. The invitation isn't found (when an invitation is claimed, it is
    //    removed).  Show the appropriate message or 404 this page

    return (
      <div>
        {loading && <Loading />}
        {value && <InviteForm
          invite={invite.value}
          user={auth.user}
          acceptInvitation={this.acceptInvitation}
          disableButton={accepting || accepted} />}
      </div>
    )
  }
}

export const Invite = connect(
  state => ({
    auth: state.auth,
    firebase: state.firebase,
  }),
  dispatch => ({
    bind: bindActionCreators(bindFirebasePath, dispatch),
    unbind: bindActionCreators(unbindFirebasePath, dispatch),
    acceptInvitation: bindActionCreators(acceptInvitation, dispatch),
  })
)(InviteContainer)
