const initialState = {
  loading: false,
  items: [],
  error: null,
  slug: null,
  balances: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PROPERTY_STATEMENT_GET':
      return {
        ...state,
        // balances: ((balances, value) => {
        //   const idx = balances.findIndex(x => x.propertyId === value.propertyId)
        //   if (idx === -1) return [...balances, value]
        //   balances[idx] = value
        //   return balances
        // })(state.balances, action.value)
        balances: {
          ...state.balances,
          [action.value.propertyId]: action.value
        }
      }
    case 'PROPERTIES_UPDATE_SAVING':
      return {
        ...state,
        saved: false,
        saving: true,
        error: null
      }
    case 'PROPERTIES_UPDATE_SUCCESS':
      return {
        ...state,
        saved: true,
        saving: false,
        error: null
      }
    case 'PROPERTIES_UPDATE_ERROR':
      return {
        ...state,
        saved: false,
        saving: false,
        error: action.error
      }

    case 'CONDO_SET_CURRENT_SLUG':
    case 'PROPERTIES_LOADING':
      return {
        ...state,
        loading: true,
        error: null,
        items: null
      }
    case 'PROPERTIES_GET':
      return {
        ...state,
        loading: false,
        items: action.items,
        error: null
      }
    case 'PROPERTIES_SLUG':
      return {
        ...state,
        slug: action.slug
      }
    case 'PROPERTIES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}
