const initialState = {
  keys: {}
}

export default (state = initialState, action) => {
  switch(action.type) {
    case "FIREBASE_REF_LOADING":
      return {
        ...state,
        keys: {
          ...state.keys,
          [action.refName]: {
            loading: true,
            ref: action.ref,
          },
        },
      }
    case "FIREBASE_REF_GET":
      // If we're not passing a value, then we need to remove the key
      if (!action.value && state.keys && state.keys[action.refName]) {
        delete state.keys[action.refName]
        return state
      }

      return {
        ...state,
        keys: {
          ...state.keys,
          [action.refName]: {
            value: action.value,
            ref: action.ref,
          },
        },
      }

    case "FIREBASE_REF_ERROR":
      return {
        ...state,
        keys: {
          ...state.keys,
          [action.refName]: {
            error: action.error,
            ref: action.ref,
            value: null,
          },
        },
      }

    default:
      return state
  }
}
