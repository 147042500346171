import React from 'react'

import { Field } from 'redux-form'

import {
  TextField,
} from 'material-ui';

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <TextField
      style={{width: '100%', maxWidth: '100%' }}
      {...input}
      {...custom}
      floatingLabelFixed={custom.type === 'date' || custom.type === 'time'}
      floatingLabelText={label}
      fullWidth
    />
  )
}

const InputField = ({type, name, label, style, required}) =>
  <Field
    name={name}
    label={label}
    type={type}
    style={{ maxWidth: '100%' }}
    required={required}
    component={renderTextField}
    />

export default InputField
