export default {
  // Auth
  auth_display_name: 'Display Name',
  auth_phone_number: 'Phone Number',
  auth_email: 'Email',
  auth_password: 'Password',
  auth_password_confirm: 'Confirm Password',
  auth_login_header: 'Please Login',
  auth_signup_header: 'SignUp Now',
  auth_login_link: 'Already have an account',

  menu_home: 'Home',
  menu_news: 'News',
  menu_files: 'Files',
  menu_properties: 'Properties',
  menu_my_property: 'My Property',
  menu_statement: 'Statement',

  coming_soon: 'Coming Soon ...',
  folder_name: 'Folder Name',
  no_files: 'No Files',
  no_info: 'No Information',

  // Auth Errors
  'auth/user-not-found': 'User was not found',
  'auth/email-already-in-use': 'Email already in use',
  'auth/wrong-password': 'Invalid Credentials',
  'auth/weak-password': 'Password should be at least 6 characters',

  auth_logout_confirm: 'Are you sure you want to logout?',
  auth_logout_dialog_title: 'Leaving already?',
  auth_logout_cancel: 'No, stay logged in',
  auth_profile_updated: 'Profile Updated',
  auth_password_recover: 'An email has been sent to recover your password.',
  passwords_do_not_match: 'Passwords submitted do not match',

  property_vehicles: 'Vehicles',
  property_contacts: 'Contacts',
  property_id: 'Property ID',
  property_registry: 'Registry',
  property_registry_id: 'Registry ID',
  property_details: 'Details',
  property_users: 'Users',

  vehicle_type: 'Type',
  vehicle_type_motorcycle: 'Motorcycle',
  vehicle_type_car: 'Car',
  vehicle_year: 'Year',
  vehicle_make: 'Make',
  vehicle_model: 'Model',
  vehicle_license: 'License',
  vehicle_access: 'Gate Access',

  contact_identification: 'Identification',
  contact_name: 'Full Name',
  contact_email: 'Email',
  contact_phone: 'Phone',

  property: 'Property',
  contact: 'Contact',
  email: 'Email',
  phone: 'Phone',
  identification: 'ID',

  errors: 'Errors',
  warnings: 'Warnings',
  import: 'Import',
  actions: 'Actions',

  your_condos: 'Your Condos',
  new_article: 'New Article',
  article_draft: 'DRAFT',
  article_title: 'Article Title',
  article_content: 'Article Content',
  article_tags: 'Article Tags',
  article_publish_date: 'Publish Date',
  article_publish_time: 'Publish Time',
  article_gallery: 'Gallery Images',
  articles_all: 'All',
  manage_administrator: 'Manage Administrators',
  upload_properties: 'Upload Properties',
  manage_settings: 'Settings',
  reset_password: 'Reset Password',
  delete_user: 'Delete User',
  upload_in_bulk: 'Upload Properties in Bulk',
  drag_and_drop: 'Drag and drop files here or click to select',
  overwrite_all_properties: 'Overwrite all properties',
  invite_user_to_manage_property: 'Invite a user to manage this property',
  invite_not_found: 'Invitation was not found',
  invitation_resent: 'Invitation has been resent',
  user_deleted: 'User has been deleted',
  // Statuses
  pending: 'Pending',

  email_invite_header: "You've been invited!",
  email_invite_copy:
    "You've been invited to manage a property in {condo}.  Please accept the invitation below.  If you don't know what this message is for, please Decline the invitation below.",
  email_invite_body:
    'Accept the invite to become a manager of the property on MiCondo.',
  email_invite_login: 'To accept this invitation you must login or register.',
  email_invite_button: 'Accept Invitation',

  email_news_header: "There's a new notification",
  email_news_body: '',
  email_news_button: 'Read',

  panel_title_files: 'Files',
  panel_title_properties: 'Properties',

  page_title_profile: 'Profile',

  saved: 'Saved',

  // Button Text
  loading: 'Loading',
  submit: 'Submit',
  save: 'Save',
  saving: 'Saving',
  search: 'Search',
  publish: 'Publish',
  confirm: 'Confirm',
  cancel: 'Cancel',
  create: 'Create',
  edit: 'Edit',
  menu: 'Menu',
  home: 'Home',
  logout: 'Logout',
  invite: 'Invite',
  delete: 'Delete',

  // Financial
  balance: 'Balance',
  calculating: 'Calculating',
  totals: 'Totals',
  last_updated: 'Updated',

  statement_generate: 'Generate Statements',
  statement_generate_send: 'Generate & Send Statements',

  statement_condo: 'Condominium',
  statement_owner: 'Owner',
  statement_range: 'Range',
  statement_currency: 'Currency',
  statement_property: 'Property',

  statement_concept: 'Concept',
  statement_date: 'Date',
  statement_credit: 'Credit',
  statement_debit: 'Debit',
  statement_balance: 'Balance',
  statement_service: 'Service',
  statement_type: 'Type',

  no_info: 'No information, please generate and try again.'
}
