import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { saveArticle, setArticle } from 'actions/articles'
import { bindFirebasePath, unbindFirebasePath } from 'actions/fbActions'
import { getCurrentCondo } from 'selectors/condos'
import moment from 'moment'
import Loading from 'components/Loading'
import ArticleForm from './ArticleForm'
import Page from 'components/Page'

const _now = moment()

class EditArticleContainer extends React.Component {

  createArticle = event => {
    const {
      saveArticle,
      condo,
      match: { params: { articleId } },
    } = this.props
    const condoId = condo.key
    const timestamp = moment(`${event.publishDate} ${event.publishTime}`, "YYYY-MM-DD HH:mm").valueOf()
    const article = {...event, timestamp, condoId, articleId }
    saveArticle(article)
    this.setState(article)
  }

  state = {
    saved: false,
    article: null,
  }

  bindArticle(props = this.props) {
    const {
      bind,
      condo,
      match: { params: { articleId } },
    } = props

    if (!condo) {
      return
    }

    bind(`/articles/${condo.key}/content/${articleId}`, 'article')
    bind(`/articles/${condo.key}/tags`, 'tags')
  }

  afterSave(props) {
    // After Save
    const {
      articles: {
        saved,
        error,
      }
    } = props

    if (!saved) {
      return
    }

    if (!error && saved) {
      this.setState({ saved: true })
    }
  }

  componentDidMount() {
    this.bindArticle()
  }

  componentWillReceiveProps(nextProps) {
    this.bindArticle(nextProps)
    this.afterSave(nextProps)
  }

  componentWillUnmount() {
    const { unbind } = this.props
    unbind(null, 'article')
    unbind(null, 'tags')
  }

  render() {
    const {
      firebase: { keys: { article, tags } },
      match: { params: { articleId } },
    } = this.props

    const { saved } = this.state

    if ((articleId && !article) || (article && article.loading)) {
      return <Loading />
    }

    if (article && article.value) {
      article.value.key = articleId
    }

    return (
      <Page title={<FormattedMessage id="new_article" />}>
        <ArticleForm
          onSubmit={this.createArticle}
          tags={tags && tags.value && Object.keys(tags.value)}
          initialValues={(article && article.value) || {
            publishDate: _now.format('YYYY-MM-DD'),
            publishTime: '00:00',
          }} />
        {saved && <strong>Article saved successfully</strong>}
      </Page>
    )
  }
}

export default connect(
  state => ({
    firebase: state.firebase,
    condo: getCurrentCondo(state),
    articles: state.articles,
  }),
  dispatch => ({
    bind: bindActionCreators(bindFirebasePath, dispatch),
    unbind: bindActionCreators(unbindFirebasePath, dispatch),
    saveArticle: bindActionCreators(saveArticle, dispatch),
    setArticle: bindActionCreators(setArticle, dispatch),
  })
)(EditArticleContainer)
