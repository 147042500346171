import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetPassword } from 'actions/authActions'
import { resendInvite, deleteUser, deleteInvite } from 'actions/properties'

import {
  IconButton,
  IconMenu,
  ListItem,
  MenuItem,
  Snackbar,
} from 'material-ui'
import PersonIcon from 'material-ui-icons/Person'
import InviteIcon from 'material-ui-icons/ContactMail'
import IconMore from 'material-ui-icons/MoreVert'
import IconDelete from 'material-ui-icons/Delete'
import IconLaunch from 'material-ui-icons/Launch'

import { withCondoAdmin } from 'utils/IsCondoAdmin'

class UserItem extends React.Component {
  state = {
    resending: false,
    deleting: false,
    deleted: false,
  }

  handleRequestClose = () => {
    this.setState({ resending: false, deleting: false })
  }

  render() {

    const {
      firebindData,
      intl: { formatMessage },
      isCondoAdmin,
      resetPassword,
      deleteUser,
      resendInvite,
      deleteInvite,
      type = 'person',
    } = this.props

    const { resending, deleting, deleted } = this.state

    let data = {}

    if (deleted) {
      return null
    }

    if (type === 'invite') {
      data = {
        icon: <InviteIcon />,
        primary: firebindData.email,
        secondary: formatMessage({id: 'pending'}),
        right: (
          <IconMenu iconButtonElement={(<IconButton
            style={{ padding: 0, margin: 0 }}
            touch={true}
            tooltipPosition="bottom-left"><IconMore /></IconButton>)}>
            <MenuItem onClick={() => {
              this.setState({ resending: true })
              resendInvite(firebindData)
            }}><IconLaunch /></MenuItem>
            <MenuItem onClick={() => {
              this.setState({ deleting: true })
              deleteInvite(firebindData)
            }}><IconDelete /></MenuItem>
          </IconMenu>
        ),
      }
    } else {
      data = {
        icon: <PersonIcon />,
        primary: firebindData.email,
        secondary: firebindData.name,
        right: !isCondoAdmin ? null : (
          <IconMenu iconButtonElement={(<IconButton
            style={{ padding: 0, margin: 0 }}
            touch={true}
            tooltipPosition="bottom-left"><IconMore /></IconButton>)}>
            <MenuItem onClick={() => { resetPassword(firebindData) }}><FormattedMessage id="reset_password" /></MenuItem>
            <MenuItem
              onClick={() => {
                deleteUser(this.props.property, this.props.condo, firebindData)
                  .then(() => {
                    this.setState({ deleted: true })
                  })
              }}><FormattedMessage id="delete_user" /></MenuItem>
          </IconMenu>),
      }
    }

    return (
      <div>
        <ListItem
          leftIcon={data.icon}
          primaryText={data.primary}
          rightIcon={data.right}
          secondaryText={data.secondary} />
        <Snackbar
          open={resending || deleting}
          message={resending ? <FormattedMessage id="invitation_resent" /> : (deleting ? <FormattedMessage id="user_deleted" /> : '...')}
          autoHideDuration={4000}
          onRequestClose={this.handleRequestClose}
        />
      </div>
    )
  }
}

export default connect(null,
  dispatch => ({
    resetPassword: bindActionCreators(resetPassword, dispatch),
    resendInvite: bindActionCreators(resendInvite, dispatch),
    deleteUser: bindActionCreators(deleteUser, dispatch),
    deleteInvite: bindActionCreators(deleteInvite, dispatch),
  })
)(injectIntl(withCondoAdmin(UserItem)))
