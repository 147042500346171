import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import CondoPicker from 'components/Condos/CondoPicker'

class DashboardContainer extends React.Component {
  render() {
    const { condos } = this.props

    if (condos && condos.length === 1) {
      return <Redirect to={`/condo/${condos[0].slug}`} />
    }

    return (
      <div>
        <CondoPicker />
      </div>
    )
  }
}

export default connect(
  state => ({
    condos: state.condos.condos,
  })
)(DashboardContainer)
