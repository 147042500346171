import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { savePropertyInfo } from 'actions/properties'

import { RaisedButton, Snackbar } from 'material-ui'

import InputField from 'components/InputField'

const Form = reduxForm({
  form: 'PropertyDetail',
})(({ handleSubmit, submitting, saved }) => {
  return (
  <form onSubmit={handleSubmit}>
    <InputField
      name="propertyId"
      label={<FormattedMessage id="property_id" />}
      style={{ width: '100%' }}
      fullWidth
      />
      <br />
    <InputField
      name="registry"
      label={<FormattedMessage id="property_registry" />}
      style={{ width: '100%' }}
      fullWidth
      />
      <br />
    <InputField
      name="registryId"
      label={<FormattedMessage id="property_registry_id" />}
      style={{ width: '100%' }}
      fullWidth
      />
      <br />
      {saved &&
        <Snackbar
          open={saved}
          message={<FormattedMessage id="saved" />}
          autoHideDuration={4000}
          onRequestClose={this.handleRequestClose}
        />}
    <RaisedButton primary={true} disabled={submitting} type="submit"><FormattedMessage id="save" /></RaisedButton>
  </form>
)})

class PropertyDetailForm extends React.Component {
  state = {
    saved: false,
  }

  submitDetail = info => {
    const { savePropertyInfo, path } = this.props
    this.setState({ saved: false })

    savePropertyInfo(path, info)
      .then(() => {
        this.setState({ saved: true })
      })
  }

  render() {
    const { data } = this.props
    const { saved } = this.state

    return (
      <Form onSubmit={this.submitDetail} initialValues={data} saved={saved} />
    )
  }
}

export default connect(
  state => ({

  }),
  dispatch => ({
    savePropertyInfo: bindActionCreators(savePropertyInfo, dispatch),
  })
)(PropertyDetailForm)
