import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Switch, Route } from 'react-router-dom'
import PrivateRoute from 'utils/PrivateRoute'
import { getCurrentCondo } from 'selectors/condos'
import { getUnInitializedProperty } from 'selectors/properties'
import { setCurrentSlug, getProperties } from 'actions/condoActions'

import { PropertyDetails } from 'components/Properties/PropertyDetails'
import { PropertyList } from 'components/Properties/PropertyList'
import { InitRedirect } from 'components/Properties/Init.js'
import CondoSettings from 'components/Condos/Settings'
import EditArticle from 'components/Condos/EditArticle'
import Page from 'components/Page'
import Panel from 'components/Panel'

import { Articles } from 'components/Condos/Articles'
import { ArticleTags } from 'components/Condos/ArticleTags'
import UploadProperties from '../UploadProperties'
import CondoFiles from 'components/Condos/CondoFiles'
import CondoStatements from 'components/Condos/Statements'
import CondoStatement from 'components/Condos/Statement'
import IsCondoAdmin from 'utils/IsCondoAdmin'

import { Grid, Row, Col } from 'react-flexbox-grid'
import qs from 'query-string'
import SearchIcon from 'material-ui-icons/Search'

const CondoFilesPage = _ => (
  <Page title={<FormattedMessage id="panel_title_files" />}>
    <Panel>
      <CondoFiles />
    </Panel>
  </Page>
)

const searchStyle = {
  input: {
    transition: 'all 300ms ease-in-out',
    width: '100%',
    border: '1px solid #AFAFAF',
    fontSize: '1.1rem',
    padding: '0.5rem',
    fontWeight: 200,
    borderRadius: '3px',
    opacity: 0
  },

  inputActive: {
    opacity: 1
  }
}

class CondoPropertiesPageContainer extends React.Component {
  state = {
    search: null,
    searchActive: false
  }

  toggleSearch = () => {
    this.setState({ searchActive: !this.state.searchActive })
  }

  doSearch = event => {
    this.setState({ search: event.target.value })
  }

  render() {
    const {
      condo,
      intl: { formatMessage }
    } = this.props
    const { search, searchActive } = this.state

    let inputStyle = searchActive
      ? {
          ...searchStyle.input,
          ...searchStyle.inputActive
        }
      : searchStyle.input

    return (
      <Page
        title={<FormattedMessage id="panel_title_properties" />}
        right={
          <Grid>
            <Row style={{ alignItems: 'center' }}>
              <Col xs>
                <div style={{ textAlign: 'right', marginRight: '1rem' }}>
                  <input
                    type="text"
                    onChange={this.doSearch}
                    style={inputStyle}
                    placeholder={formatMessage({ id: 'search' })}
                  />
                </div>
              </Col>
              <Col xs={2}>
                <SearchIcon onClick={this.toggleSearch} />
              </Col>
            </Row>
          </Grid>
        }
      >
        <Panel>
          <PropertyList condo={condo} search={search} />
        </Panel>
      </Page>
    )
  }
}

const CondoPropertiesPage = connect(state => ({
  condo: getCurrentCondo(state)
}))(injectIntl(CondoPropertiesPageContainer))

// const ComingSoon = () => (
//   <Page title={<FormattedMessage id="menu_statement" />}>
//     <FormattedMessage id="coming_soon" />
//   </Page>
// )

const Statements = () => (
  <Page title={<FormattedMessage id="menu_statement" />}>
    <CondoStatements />
  </Page>
)

const Statement = ({ match }) => (
  <Page title={<FormattedMessage id="menu_statement" />}>
    <CondoStatement {...match.params} />
  </Page>
)

class CondoDashboard extends React.Component {
  componentDidMount() {
    const { condo, getProperties, match, setCurrentSlug } = this.props

    setCurrentSlug(match.params.condoId)
    condo && getProperties(condo)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.condoId !== nextProps.match.params.condoId) {
      this.props.setCurrentSlug(nextProps.match.params.condoId)
    }

    if (
      (!this.props.condo && nextProps.condo) ||
      (nextProps.condo && this.props.condo.key !== nextProps.condo.key)
    ) {
      nextProps.getProperties(nextProps.condo)
    }
  }

  componentWillUnmount() {
    this.props.setCurrentSlug(null)
  }

  render() {
    const {
      condo,
      init,
      location: { search }
    } = this.props

    const tagFilter = search && qs.parse(search)

    if (init && condo) {
      return <InitRedirect condo={condo.key} property={init.key} />
    }

    return (
      <div>
        <Switch>
          <PrivateRoute
            path="/condo/:condoId/properties/upload"
            component={UploadProperties}
          />
          <PrivateRoute
            path="/condo/:condoId/properties/:propertyId"
            component={PropertyDetails}
          />
          <PrivateRoute
            path="/condo/:condoId/user/create"
            component={UploadProperties}
          />
          <PrivateRoute
            path="/condo/:condoId/news/add"
            component={EditArticle}
          />
          <PrivateRoute
            path="/condo/:condoId/news/:articleId"
            component={EditArticle}
          />
          <PrivateRoute
            path="/condo/:condoId/settings"
            component={CondoSettings}
          />
          <PrivateRoute
            path="/condo/:condoId/files"
            component={CondoFilesPage}
          />
          <PrivateRoute
            path="/condo/:condoId/properties"
            component={CondoPropertiesPage}
          />
          <PrivateRoute
            path="/condo/:condoId/statement/:propertyId"
            component={Statement}
          />
          <PrivateRoute
            path="/condo/:condoId/statement"
            component={Statements}
          />

          <Route
            render={() => (
              <Page>
                <Row>
                  <Col xs={12} sm>
                    <IsCondoAdmin otherwise={<Articles condo={condo} />}>
                      <Articles
                        condo={condo}
                        tagFilter={tagFilter && tagFilter.tag}
                        state="all"
                      />
                    </IsCondoAdmin>
                  </Col>
                  <ArticleTags
                    condo={condo}
                    current={tagFilter && tagFilter.tag}
                  />
                </Row>
              </Page>
            )}
          />
        </Switch>
      </div>
    )
  }
}

export default connect(
  state => ({
    condo: getCurrentCondo(state),
    init: getUnInitializedProperty(state)
  }),
  dispatch => ({
    setCurrentSlug: bindActionCreators(setCurrentSlug, dispatch),
    getProperties: bindActionCreators(getProperties, dispatch)
  })
)(CondoDashboard)
