import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import Panel from 'components/Panel'

import { RaisedButton } from 'material-ui'

import { stylesheet } from 'styles'

import InputField from 'components/InputField'

const validate = ({ email, password, confirmPassword }) => {
  const errors = {}
  if (password && confirmPassword && password !== confirmPassword) {
    errors.password = 'passwords_do_not_match'
  }
  return errors
}

const SignUp = ({
  user,
  handleSubmit,
  submitting,
  intl: { formatMessage }
}) => (
  <Panel heading={<FormattedMessage id="auth_signup_header" />}>
    <form onSubmit={handleSubmit}>
      <div>
        <InputField
          name="email"
          type="email"
          label={formatMessage({ id: 'auth_email' })}
          required={true}
        />
      </div>
      <div>
        <InputField
          name="password"
          type="password"
          label={formatMessage({ id: 'auth_password' })}
          required={true}
        />
      </div>
      <div>
        <InputField
          name="confirmPassword"
          type="password"
          label={formatMessage({ id: 'auth_password_confirm' })}
        />
      </div>

      <RaisedButton
        primary={true}
        type="submit"
        fullWidth={true}
        disabled={submitting}
      >
        <FormattedMessage id="submit" />
      </RaisedButton>

      <Link to="/auth/login" style={stylesheet.authLink}>
        <FormattedMessage id="auth_login_link" />
      </Link>
    </form>
  </Panel>
)

export default reduxForm({
  form: 'SignUpForm',
  validate
})(injectIntl(SignUp))
