import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, user = false, ...rest }) => {
  if (user === false) {
    return null
  }

  function renderRoute(props) {
    return  (
      user 
        ? (<Component {...props}/>)
        : (<Redirect to={{
            pathname: '/auth/login',
            state: { from: props.location }
          }}/>)
    )
  }

  return (
    <Route {...rest} render={renderRoute}/>
  )
}

export default connect(
  state => ({
    user: state.auth.user
  })
)(PrivateRoute)
