import React from 'react'
import { Route, Switch } from 'react-router-dom'

import PrivateRoute from '../utils/PrivateRoute'

import Tos from 'components/Tos'

import AppLayout from '../layouts/AppLayout'
import AuthLayout from '../layouts/AuthLayout'

const NoMatch = (props) => (
  <h1>404</h1>
)

export default function(store) {
  return (
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route exact path="/tos" component={Tos} />
      <PrivateRoute path="/" component={AppLayout} />
      <Route component={NoMatch} />
    </Switch>
  )
}
