import { auth, database } from 'utils/firebase'

export const initProperty = (condo, property, next) => {
  return dispatch => {
    const ref = database.ref(`/propertyInfoQueue/${condo}/${property}/init`)
    ref
      .update({ value: Date.now() })
      .then(() => ({
        type: 'PROPERTIES_INIT'
      }))
      .then(dispatch)
      .then(next)
  }
}

export const saveVehicle = (condo, property, vehicle, idx = false) => {
  return dispatch => {
    dispatch({
      type: 'PROPERTIES_UPDATE_SAVING'
    })
    const path = `/propertyInfoQueue/${condo.key}/${property.key}/vehicles`

    const ref = database.ref(path).push()
    return ref
      .update({
        condo: condo.key,
        property: property.key,
        status: 'pending',
        user: auth.currentUser.uid,
        action: 'save',
        index: idx,
        ...vehicle
      })
      .then(res => ({
        type: 'PROPERTIES_UPDATE_SUCCESS'
      }))
      .catch(error => ({
        type: 'PROPERTIES_UPDATE_ERROR',
        error
      }))
      .then(dispatch)
  }
}

export const savePropertyInfo = (path, info) => dispatch => {
  dispatch({
    type: 'PROPERTIES_UPDATE_SAVING'
  })

  Object.keys(info).forEach(key => info[key] === undefined && delete info[key])

  const ref = database.ref(path)
  return ref
    .update(info)
    .then(res => ({
      type: 'PROPERTIES_UPDATE_SUCCESS'
    }))
    .catch(error => ({
      type: 'PROPERTIES_UPDATE_ERROR',
      error
    }))
    .then(dispatch)
}

export const saveContact = (condo, property, contact, idx = false) => {
  return dispatch => {
    dispatch({
      type: 'PROPERTIES_UPDATE_SAVING'
    })
    const path = `/propertyInfoQueue/${condo.key}/${property.key}/contacts`

    const ref = database.ref(path).push()
    ref
      .update({
        condo: condo.key,
        property: property.key,
        status: 'pending',
        user: auth.currentUser.uid,
        action: 'save',
        index: idx,
        ...contact
      })
      .then(res => ({
        type: 'PROPERTIES_UPDATE_SUCCESS'
      }))
      .catch(error => ({
        type: 'PROPERTIES_UPDATE_ERROR',
        error
      }))
      .then(dispatch)
  }
}

export const deleteItem = type => (condo, property, item) => dispatch => {
  dispatch({ type: 'PROPERTIES_UPDATE_SAVING' })

  const path = `/properties/${condo.key}/${property.key}/${type}/${item.key}`

  return database
    .ref(path)
    .remove()
    .then(res => ({
      type: 'PROPERTIES_UPDATE_SUCCESS'
    }))
    .catch(error => ({
      type: 'PROPERTIES_UPDATE_ERROR',
      error
    }))
    .then(dispatch)
}

function checkForValidInvite(condoKey, propertyKey, email, values) {
  if (!values) {
    return true
  }

  // TODO: Check if the user hasn't already been invited.
  const foundInvite =
    values &&
    Object.keys(values).find(key => {
      const i = values[key]
      return (
        i.email === email && i.condo === condoKey && i.property === propertyKey
      )
    })

  return !foundInvite
}

export const resendInvite = ({ condo, property, key }) => {
  return dispatch => {
    dispatch({
      type: 'AUTH_RESEND_LOADING'
    })

    const invite = database.ref(
      `invitations/${condo}/${property}/${key}/status`
    )
    return invite.set('resend')
  }
}

export const deleteInvite = ({ condo, property, key }) => dispatch => {
  dispatch({
    type: 'AUTH_DELETE_USER_FROM_PROPERTY'
  })

  const ref = database.ref(`invitations/${condo}/${property}/${key}`)
  return ref.remove()
}

export const deleteUser = (property, condo, user) => dispatch => {
  dispatch({
    type: 'AUTH_DELETE_USER_FROM_PROPERTY'
  })

  const ref = database.ref(
    `properties/${condo.key}/${property.key}/users/${user.uid}`
  )
  return ref.remove()
}

export const updateStatements = (
  condo,
  properties,
  action = 'generate',
  force = false
) => dispatch => {
  return Promise.all(
    Object.keys(properties).map(propertyId => {
      const statement = database.ref(
        `propertyStatementQueue/${condo.key}/${propertyId}`
      )

      statement.once('value', snapshot => {
        if (!force && snapshot.exists()) {
          const value = snapshot.val()
          if (Date.now() - value.updated < 3 * 60 * 60 * 1000)
            return dispatch({ type: 'PROPERTY_STATEMENT_GET', value })
        }
        const init = {
          propertyId: propertyId,
          condoId: condo.key,
          status: 'pending',
          timestamp: Date.now(),
          balance: 0,
          action
        }
        return statement
          .update(init)
          .then(() => init)
          .then(value => ({ type: 'PROPERTY_STATEMENT_GET', value }))
          .catch(error => ({ type: 'PROPERTY_STATEMENT_ERROR', error }))
          .then(dispatch)
      })

      return true
    })
  )
}

export const inviteUser = (condo, property, email, callback) => {
  return dispatch => {
    const invites = database.ref(`invitations/${condo.key}/${property.key}`)
    const invite = invites.push()

    invites
      .once('value')
      .then(snapshot => snapshot.val())
      // Validate Invitation
      .then(values =>
        checkForValidInvite(condo.key, property.key, email, values)
      )
      .then(valid => {
        if (!valid) {
          throw new Error('Invalid Email')
        }
        return invite.set({
          email,
          condo: condo.key,
          condoName: condo.name,
          condoSlug: condo.slug,
          property: property.key,
          timestamp: Date.now(),
          invitedBy: auth.currentUser.uid,
          status: 'pending'
        })
      })
      .then(_ => ({
        type: 'PROPERTY_INVITE_SUCCESS'
      }))
      .catch(error => ({
        type: 'PROPERTY_INVITE_ERROR',
        error
      }))
      .then(dispatch)
      // I don't know if I'm supposed to get away with this
      .then(_ => {
        if (callback) {
          callback()
        }
      })
  }
}
