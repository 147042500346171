import React from 'react'
import { withRouter } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import IsCondoAdmin from 'utils/IsCondoAdmin'
import { IconButton, Popover, MenuItem } from 'material-ui'

import IconMore from 'material-ui-icons/MoreHoriz'

import { stylesheet } from 'styles'

let AppBarMenuItem = ({
  to,
  isMenuItem = false,
  children,
  renderChildren,
  location: { pathname }
}) => {
  let styles = stylesheet.appBarMenuItem

  if (pathname === to) {
    styles = { ...styles, ...stylesheet.appBarMenuItemActive }
  }

  if (renderChildren) {
    return renderChildren(children, to)
  }

  return (
    <Link to={to} style={isMenuItem ? {} : styles}>
      {children}
    </Link>
  )
}

AppBarMenuItem = withRouter(AppBarMenuItem)

class CondoMenuContainer extends React.Component {
  state = {
    anchorEl: undefined,
    open: false
  }

  button = undefined

  handleClick = event => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { condoId } = this.props.match.params
    const { renderChildren, isLeftMenu = false } = this.props

    const adminMenu = isLeftMenu ? (
      <div>
        <AppBarMenuItem
          renderChildren={renderChildren}
          to={`/condo/${condoId}/news/add`}
          isMenuItem={true}
        >
          <FormattedMessage id="new_article" />
        </AppBarMenuItem>
        <AppBarMenuItem
          renderChildren={renderChildren}
          to={`/condo/${condoId}/settings`}
          isMenuItem={true}
        >
          <FormattedMessage id="manage_settings" />
        </AppBarMenuItem>
        <AppBarMenuItem
          renderChildren={renderChildren}
          to={`/condo/${condoId}/properties/upload`}
          isMenuItem={true}
        >
          <FormattedMessage id="upload_properties" />
        </AppBarMenuItem>
      </div>
    ) : (
      <IconButton color="contrast" style={{ height: 'auto', padding: 0 }}>
        <IconMore
          onClick={this.handleClick}
          color="#FFFFFF"
          style={{ marginBottom: '-5px' }}
        />
        <Popover
          id="condo-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          <MenuItem>
            <AppBarMenuItem
              renderChildren={renderChildren}
              to={`/condo/${condoId}/news/add`}
              isMenuItem={true}
            >
              <FormattedMessage id="new_article" />
            </AppBarMenuItem>
          </MenuItem>
          <MenuItem>
            <AppBarMenuItem
              renderChildren={renderChildren}
              to={`/condo/${condoId}/settings`}
              isMenuItem={true}
            >
              <FormattedMessage id="manage_settings" />
            </AppBarMenuItem>
          </MenuItem>
          <MenuItem>
            <AppBarMenuItem
              renderChildren={renderChildren}
              to={`/condo/${condoId}/properties/upload`}
              isMenuItem={true}
            >
              <FormattedMessage id="upload_properties" />
            </AppBarMenuItem>
          </MenuItem>
        </Popover>
      </IconButton>
    )

    return (
      <span className="micondo-condomenu">
        <AppBarMenuItem
          renderChildren={renderChildren}
          to={`/condo/${condoId}`}
        >
          <FormattedMessage id="menu_news" />
        </AppBarMenuItem>
        <AppBarMenuItem
          renderChildren={renderChildren}
          to={`/condo/${condoId}/files`}
        >
          <FormattedMessage id="menu_files" />
        </AppBarMenuItem>
        <AppBarMenuItem
          renderChildren={renderChildren}
          to={`/condo/${condoId}/properties`}
        >
          <IsCondoAdmin otherwise={<FormattedMessage id="menu_my_property" />}>
            <FormattedMessage id="menu_properties" />
          </IsCondoAdmin>
        </AppBarMenuItem>
        <AppBarMenuItem
          renderChildren={renderChildren}
          to={`/condo/${condoId}/statement`}
        >
          <FormattedMessage id="menu_statement" />
        </AppBarMenuItem>
        <IsCondoAdmin>{adminMenu}</IsCondoAdmin>
      </span>
    )
  }
}

export default withRouter(CondoMenuContainer)
