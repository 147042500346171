import { database } from 'utils/firebase'

function refValue(refName, value, ref) {
  return {
    type: "FIREBASE_REF_GET",
    refName,
    value,
    ref,
  }
}

function refError(refName, error, ref) {
  return {
    type: "FIREBASE_REF_ERROR",
    refName,
    error,
    ref,
  }
}

const bindings = {}

function getBinding(path, refName) {
  return bindings[refName]
}

function removeBinding(refName) {
  delete bindings[refName]
}

function setBinding(path, refName, value) {
  bindings[refName] = value
  return value
}

export const unbindFirebasePath = function(path, refName) {
  return dispatch => {
    removeBinding(refName)
    dispatch(refValue(refName))
  }
}

function parse(url) {
  if (!url) {
    return null
  }
  const [path, query] = url.split('?')
  const ret = { path }

  if (query ) {
    const args = query.split('&')

    args.length && args.forEach(arg => {
      const [key, val] = arg.split('=')
      ret[key] = val
    })
  }

  return ret
}

export const bindFirebasePath = function(path, refName, watch = false) {
  if (getBinding(path, refName)) {
    return dispatch => null
  }
  const parsedPath = parse(path)
  let ref = setBinding(path, refName, database.ref(parsedPath.path))

  if (parsedPath.orderByChild) {
    ref = ref.orderByChild(parsedPath.orderByChild)
  }

  if (parsedPath.orderByKey) {
    ref = ref.orderByKey()
  }

  function onValueChange(snapshot) {
    const val = snapshot.val()

    // Account for weird stuff with firebase ordering
    if (parsedPath.asArray) {
      const ret = []
      snapshot.forEach(s => {
        ret.push({
          key: s.key,
          ...s.val(),
        })
      })

      if (parsedPath.asArray === 'reverse') {
        ret.reverse()
      }

      return refValue(refName, ret, ref)
    }


    // Something is wrong or doesn't exist
    if (!val) {
      return refError(refName, {
        code: 'does_not_exist',
        message: `${path} does not exist`,
      }, ref)
    }

    return refValue(refName, val, ref)
  }

  function onError(error) {
    return refError(refName, error, ref)
  }

  function valueChanged(dispatch) {
    return snapshot => {
      dispatch(onValueChange(snapshot))
    }
  }

  return dispatch => {
    dispatch({
      type: "FIREBASE_REF_LOADING",
      refName,
      ref,
    })
    if (watch) {
      ref.on('value', valueChanged(dispatch))
      // ref.on('child_changed', valueChanged(dispatch))
    } else {
      ref.once('value')
        .then(onValueChange)
        .catch(onError)
        .then(dispatch)
    }
  }
}
