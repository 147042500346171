import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Typography from 'components/Typography'

const Page = ({title, children, right}) => (
  <Grid className="micondo-page" fluid>
    {title && (
      <Row>
        <Col xs>
          <Typography type="headline" style={{ fontWeight: 400, fontSize: '1.5rem' }}>{title}</Typography>
        </Col>
        {right && (<Col xs={6} sm={3}>{right}</Col>)}
      </Row>
    )}
    {children}
  </Grid>
)

export default Page
