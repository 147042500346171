import { database, storage } from '../utils/firebase'
import { updateStatements } from './properties'

export function setCurrentCondo(current) {
  return {
    type: 'CONDO_SET_CURRENT',
    current
  }
}

export function setCurrentSlug(slug) {
  return {
    type: 'CONDO_SET_CURRENT_SLUG',
    slug
  }
}

function _condosGet(condos) {
  return {
    type: 'CONDO_SET_CONDOS',
    condos
  }
}

function _condosError(error) {
  return {
    type: 'CONDO_GET_ERROR',
    error
  }
}

function _propertiesGet(items) {
  return {
    type: 'PROPERTIES_GET',
    items
  }
}

function _propertiesLoading() {
  return {
    type: 'PROPERTIES_LOADING'
  }
}

function _propertiesError(error) {
  return {
    type: 'PROPERTIES_ERROR',
    error
  }
}

export function setCurrentPropertySlug(slug) {
  return {
    type: 'PROPERTIES_SLUG',
    slug
  }
}

function mapKeys(s) {
  return {
    key: s.key,
    ...(s.val() || s)
  }
}

function mapSnapshots(snapshots) {
  return snapshots.map(mapKeys)
}

function addSnapshotValue(data) {
  return items =>
    items.map(item => ({
      _value: data[item.key] || false,
      ...item
    }))
}

function onValue(ref) {
  return new Promise((yup, nope) => {
    ref.on('value', snapshot => yup(snapshot.val()), nope)
  })
}

function getCondos(dispatch, user) {
  database
    .ref(`condoUsers/${user.uid}/condos`)
    .once('value')
    .then(snapshot => {
      const data = snapshot.val()

      if (!data) {
        return dispatch(_condosError('Invalid data'))
      }

      const gets = Object.keys(data)
        .filter(key => key !== 'all')
        .map(key => `condominiums/${key}`)
        .map(key => database.ref(key).once('value'))

      Promise.all(gets)
        .then(mapSnapshots)
        .then(addSnapshotValue(data))
        .then(_condosGet)
        .then(dispatch)
    })
}

function getCondoProperties(condo) {
  return onValue(database.ref(`properties/${condo.key}`))
    .then(data => {
      Object.keys(data).forEach(k => (data[k].key = k))
      return data
    })
    .then(_propertiesGet)
    .catch(_propertiesError)
}

// function logPromise(l) {
//   console.log(l);
//   return l
// }

function toObjectCollection(key) {
  return data =>
    data.reduce((ret, i) => {
      ret[i[key]] = i
      return ret
    }, {})
}

function getCondoPropertiesByKeys(condo, properties) {
  const data = properties

  const gets = Object.keys(data)
    .map(key => `properties/${condo.key}/${key}`)
    .map(key => database.ref(key).once('value'))

  return Promise.all(gets)
    .then(mapSnapshots)
    .then(addSnapshotValue(data))
    .then(toObjectCollection('key'))
    .then(_propertiesGet)
    .catch(_propertiesError)
}

// TODO: Finish detaching when component unmounts
export const detatchProperties = condo => {
  return (dispatch, getState) => {
    // const user = getState().auth.user
  }
}

export const getProperties = condo => {
  return dispatch => {
    dispatch(_propertiesLoading)

    if (condo._value === 'admin' || condo._value === 'owner') {
      return getCondoProperties(condo).then(dispatch)
    }

    return getCondoPropertiesByKeys(condo, condo._value)
      .then(dispatch)
      .then(properties => {
        updateStatements(condo, properties.items)(dispatch)
        return properties
      })
  }
}

export const uploadFile = (filePath, customMetadata, file) => {
  return dispatch => {
    const ref = storage.ref(filePath)
    const uploadTask = ref.put(file, { customMetadata })
    uploadTask.on(
      'state_changed',
      snapshot => {},
      error => {
        console.warn(error)
      }
    )
  }
}

export const init = () => {
  return (dispatch, getState) => {
    const user = getState().auth.user
    getCondos(dispatch, user)
  }
}
