import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import makeRootReducer from './reducers'

const logger = createLogger({
  collapsed: true,
})

export default (initialState = {}) => {

  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [ thunk ]

  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    middleware.push(logger)
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer,
    initialState,
    applyMiddleware(...middleware),
  )
  return store
}
