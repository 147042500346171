import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import { app, condos, auth, properties, firebase, articles } from './../reducers'

export default combineReducers({
  // Add sync reducers here
  form,
  app,
  condos,
  auth,
  properties,
  firebase,
  articles,
})
