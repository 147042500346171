import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
// import { bindActionCreators } from 'redux'

class LoadingContainer extends React.Component {
  render() {
    return (<span><FormattedMessage id="loading" /> ...</span>)
  }
}

export default connect(
  state => ({

  }),
)(LoadingContainer)
