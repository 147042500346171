import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { signin } from 'actions/authActions'
import ReturnUrlRedirect from 'utils/ReturnUrlRedirect'
import Loading from 'components/Loading'

import Login from './Component'

class LoginContainer extends React.Component {
  handleLogin(loginData) {
    return this.props.signin(loginData)
  }

  render() {
    const {
      auth,
    } = this.props

    if (auth === undefined) {
      return <Loading />
    }

    if (auth.user) {
      return <ReturnUrlRedirect to={'/dashboard'} />
    }

    return (
      <Login user={auth.user} onSubmit={this.handleLogin.bind(this)} submitError={auth.error} />
    )
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  dispatch => ({
    signin: bindActionCreators(signin, dispatch)
  })
)(LoginContainer)
