import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import qs from 'query-string'

const ReturnUrlRedirect = ({to, location, ...props}) => {
  const query = location && qs.parse(location.search)
  return <Redirect to={query.return_url || to} {...props} />
}

export default withRouter(ReturnUrlRedirect)
