import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Typography from 'components/Typography'
import { Link } from 'react-router-dom'
import { getCurrentCondo } from '../selectors/condos'
import { setCurrentSlug } from '../actions/condoActions'

import { stylesheet } from 'styles'

// import RightArrow from 'material-ui-icons/KeyboardArrowRight'

const AppBarTitle = ({
  label,
  currentCondo,
  className,
  setCurrentSlug,
  children
}) => (
  <Typography
    type="display"
    color="inherit"
    className={className}
    style={stylesheet.app.title}
  >
    {!currentCondo && (
      <Link to="/dashboard" onClick={() => setCurrentSlug(null)}>
        {children || 'MiCondo'}
      </Link>
    )}
    {currentCondo && (
      <Link to={`/condo/${currentCondo.slug}`}>{currentCondo.name}</Link>
    )}
  </Typography>
)

export default connect(
  state => ({
    currentCondo: getCurrentCondo(state)
  }),
  dispatch => ({
    setCurrentSlug: bindActionCreators(setCurrentSlug, dispatch)
  })
)(AppBarTitle)
