import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { bindFirebasePath, unbindFirebasePath } from 'actions/fbActions'
import Loading from 'components/Loading'
import { Col } from 'react-flexbox-grid'

import { Link } from 'react-router-dom'
import Panel from 'components/Panel'

import { stylesheet } from 'styles'

const TagLink = ({ tag: { key }, condo, current }) => {
  const query = key !== '' ? `?tag=${key}` : ''
  const label =
    key !== '' ? (
      <span>{key.toUpperCase()}</span>
    ) : (
      <FormattedMessage id="articles_all" />
    )
  const isCurrent = key === current

  let styles = stylesheet.tags.tag

  if (isCurrent) {
    styles = { ...styles, ...stylesheet.tags.active }
  }

  return (
    <Panel style={styles} padding="0">
      <Link to={`/condo/${condo}${query}`}>
        <label style={stylesheet.tags.tagLabel}>{label}</label>
      </Link>
    </Panel>
  )
}

class ArticleTagsContainer extends React.Component {
  bind(props = this.props) {
    const { condo, bind } = props

    if (!condo) {
      return
    }

    bind(`/articles/${condo.key}/tags?orderByKey=true&asArray=true`, 'tags')
  }

  componentWillReceiveProps(nextProps) {
    const { condo, unbind } = this.props
    const newCondo = nextProps.condo

    if (!newCondo) {
      return
    }

    if (condo && newCondo.key !== condo.key) {
      unbind(null, 'tags')
    }

    this.bind(nextProps)
  }

  componentDidMount() {
    this.bind()
  }

  componentWillUnmount() {
    const { unbind } = this.props
    unbind(null, 'tags')
  }

  render() {
    const {
      condo,
      firebase: {
        keys: { tags }
      },
      current
    } = this.props

    if (!tags || tags.loading || !condo) {
      return <Loading />
    }

    return (
      <Col xs={12} md={3}>
        <TagLink tag={{ key: '' }} condo={condo.key} current={current} />
        {tags.value &&
          tags.value.map(item => (
            <TagLink
              key={item.key}
              tag={item}
              condo={condo.key}
              current={current}
            />
          ))}
      </Col>
    )
  }
}

export const ArticleTags = connect(
  state => ({
    firebase: state.firebase
  }),
  dispatch => ({
    bind: bindActionCreators(bindFirebasePath, dispatch),
    unbind: bindActionCreators(unbindFirebasePath, dispatch)
  })
)(ArticleTagsContainer)
