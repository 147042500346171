import { createSelector } from 'reselect'

const condos = state => state.condos.condos
const current = state => state.condos.current

export const getCurrentCondo = createSelector(
  condos,
  current,
  (_condos, _current) => {
    return !!_condos.length && _current && _condos
      .find(condo => condo.slug === _current)
  }
)

export const isCondoAdmin = createSelector(
  getCurrentCondo,
  _current => _current && (_current._value === 'admin' || _current._value === 'owner')
)
