const initialState = {
  user: null,
  loading: false,
  loaded: false,
  passwordReset: false,
  updated: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case "AUTH_RESET_LOADING":
      return {
        ...state,
        resetLoading: true,
        resetDone: false,
        resetError: null,
      }
    case "AUTH_RESET_SUCCESS":
      return {
        ...state,
        resetLoading: false,
        resetDone: true,
        resetError: null,
      }
    case "AUTH_RESET_ERROR":
      return {
        ...state,
        resetLoading: false,
        resetDone: true,
        resetError: action.error,
      }
    case "AUTH_PASSWORD_RESET":
      return {
        ...state,
        passwordReset: true,
        error: null,
      }
    case "AUTH_LOADED":
      return {
        ...state,
        loaded: true,
        error: null,
      }
    case "AUTH_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
        updated: false,
      }
    case "AUTH_SIGNIN":
      return {
        ...state,
        user: action.user,
        loading: false,
      }
    case "AUTH_PROFILE_UPDATED":
      return {
        ...state,
        error: null,
        loading: false,
        updated: true,
      }
    case "AUTH_LOGOUT":
      return {
        ...state,
        user: null,
        loading: false,
      }
    case "AUTH_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}
