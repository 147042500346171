import React from 'react'
import { FormattedDate } from 'react-intl'
import moment from 'moment'

import { stylesheet } from 'styles'

const Date = ({ date }) => (
  <span style={stylesheet.dates}>
    <FormattedDate
      value={moment(date).format('DD MMM YYYY')}
      year="numeric"
      day="numeric"
      month="short"
    />
  </span>
)

export default Date
