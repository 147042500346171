import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import {
  IconButton,
  Dialog,
  RaisedButton,
  ListItem,
} from 'material-ui'
import PowerOff from 'material-ui-icons/PowerSettingsNew'
import { signout } from '../actions/authActions'


class Logout extends React.Component {
  state = {
    open: false,
  }

  logout = () => {
    this.props.signout()
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  render() {
    const {
      color,
      children,
      menuItem,
    } = this.props

    const {
      open,
    } = this.state

// <IconButton onClick={this.handleOpen} color={color}></IconButton>
    return (
      <div>
        { !menuItem && <IconButton onClick={this.handleOpen}> { children || <PowerOff color={color} />} </IconButton> }
        {  menuItem && <ListItem onClick={this.handleOpen} primaryText={<FormattedMessage id="logout" />} leftIcon={<PowerOff color={color} />} /> }
        <Dialog
          contentStyle={{ width: '50%' }}
          bodyStyle={{ paddingLeft: '25px' }}
          open={open}
          onRequestClose={this.handleClose}
          title={<div><FormattedMessage id="auth_logout_dialog_title" /></div>}
          actions={
            <div>
              <RaisedButton style={{ width: 'auto', whiteSpace: 'nowrap', marginRight: '5px' }} buttonStyle={{ paddingRight: '10px', paddingLeft: '10px'}} onClick={this.handleClose}  primary={true} ><FormattedMessage id="auth_logout_cancel" /></RaisedButton>
              <RaisedButton onClick={this.logout} secondary={true} buttonStyle={{ color: '#FFFFFF' }}><FormattedMessage id="confirm" /></RaisedButton>
            </div>}>
          <FormattedMessage id="auth_logout_confirm" />
        </Dialog>
      </div>
    )
  }
}

export default connect(
  state => ({
    user: state.auth.user,
  }),
  dispatch => ({
    signout: bindActionCreators(signout, dispatch)
  })
)(Logout)
