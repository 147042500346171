import React from 'react'
import url from 'url'
import PropTypes from 'prop-types'
import { database, auth } from 'utils/firebase'
import Loading from 'components/Loading'

export function bindFire(WrappedComponent, { refs }) {
  if (!refs) {
    throw new Error('`refs` must be defined')
  }

  function getDisplayName(component) {
    return component.displayName || component.name || 'Component'
  }

  const name = getDisplayName(WrappedComponent)

  const fbInject = {
    fb: {
      user: auth && auth.currentUser,
      refs: null
    }
  }

  class BindFire extends React.Component {
    static WrappedComponent = WrappedComponent
    static displayName = `BindFire(${name})`

    componentDidMount() {
      const { match, ...otherProps } = this.props

      if (!refs) {
        return
      }
      fbInject.fb.refs = Object.keys(refs).reduce((ret, key) => {
        const path = refs[key]
        let refPath = typeof path === 'string' && path

        if (!refPath && match) {
          refPath = path(match, otherProps)
        }
        ret[key] = database.ref(refPath)
        return ret
      }, {})
    }

    render() {
      return <WrappedComponent {...this.props} {...fbInject} />
    }
  }

  return BindFire
}

export default class Firebind extends React.Component {
  static propTypes = {
    path: PropTypes.string.isRequired
  }

  state = {
    ref: null,
    firebindData: null,
    exists: null,
    error: null
  }

  onValueChange = snapshot => {
    this.setState({
      exists: snapshot.val() !== null,
      firebindData: snapshot.val(),
      error: null
    })
  }

  onError = error => {
    if (this.props.onError) {
      this.props.onError(error)
    }
    console.warn('FIREBIND ERROR', error)

    try {
      this.setState({
        exists: false,
        firebindData: null,
        error
      })
    } catch (e) {}
  }

  componentDidMount() {
    const { path, bindType = 'once' } = this.props
    if (!path) {
      return
    }

    // Parse Path
    let _path = url.parse(path, true)

    const ref = database.ref(_path.pathname)
    this.setState({ ref })

    if (_path.query) {
      // OrderBy stuff
      ;['Child', 'Key', 'Value'].forEach(o => {
        const order = `orderBy${o}`
        if (_path.query[order]) {
          ref[order](_path.query[order])
        }
      })

      if (_path.query.equalTo) {
        ref.equalTo(_path.query.equalTo)
      }
    }

    if (bindType === 'once') {
      ref
        .once('value')
        .then(this.onValueChange)
        .catch(this.onError)
    } else {
      ref.on('value', this.onValueChange, this.onError)
    }
  }

  componentWillUnmount() {
    const { ref } = this.state
    ref && ref.off('value', this.onValueChange)
  }

  render() {
    const {
      loading = <Loading />,
      children,
      path,
      notFound = null,
      render = null,
      hideIf = null
    } = this.props

    const { firebindData = null, exists } = this.state

    if (exists === false) {
      console.warn(`The specified path was not found or you do not
        permission to access it: ${path}`)
      return notFound
    }

    if (!firebindData) {
      return loading
    }

    if (hideIf && hideIf(firebindData)) {
      return null
    }

    if (render) {
      return <div className="Firebind__container">{render(firebindData)}</div>
    }

    if (!children) {
      return <span>{firebindData}</span>
    }

    const childrenWithProps = React.cloneElement(children, {
      firebindData,
      ...children.props
    })

    return childrenWithProps
  }
}
