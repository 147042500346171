import React from 'react'
import { withCondo } from 'utils'
import { FormattedMessage, FormattedDate } from 'react-intl'
import Loading from 'components/Loading'
import Firebind from 'components/Firebind'
import Currency from 'components/Currency'
import './styles.css'

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter
  // Grid,
} from 'material-ui'

const Header = ({ id, ...others }) => (
  <TableHeaderColumn {...others}>
    <FormattedMessage id={id} />
  </TableHeaderColumn>
)
const Money = ({ value, noElipses, width = 100, ...others }) => (
  <TableRowColumn
    className={`money ${noElipses !== undefined ? 'no-elipses' : ''}`}
    width={width}
    {...others}
  >
    <Currency value={value} />
  </TableRowColumn>
)
const Range = ({ start, end }) => (
  <div>
    <FormattedDate value={start} year="numeric" month="long" />
    <span> - </span>
    <FormattedDate value={end} year="numeric" month="long" />
  </div>
)
const StatementDetail = ({ firebindData }) => {
  const {
    condominio,
    condomino,
    fecha1,
    fecha2,
    filial,
    moneda,
    detalle
  } = firebindData

  const summary = detalle.reduce(
    (res, item) => ({
      credit: res.credit + item.montoCredito,
      debit: res.debit + item.montoDebito,
      balance: res.balance + item.montoDebito - item.montoCredito
    }),
    { credit: 0, debit: 0, balance: 0 }
  )

  return (
    <div className="statement-container">
      <header>
        <div className="header-left">
          <Table selectable={false}>
            <TableBody displayRowCheckbox={false}>
              <TableRow>
                <TableHeaderColumn>
                  <FormattedMessage id="statement_condo" />
                </TableHeaderColumn>
                <TableRowColumn>{condominio}</TableRowColumn>
              </TableRow>
              <TableRow>
                <TableHeaderColumn>
                  <FormattedMessage id="statement_owner" />
                </TableHeaderColumn>
                <TableRowColumn>{condomino}</TableRowColumn>
              </TableRow>
              <TableRow>
                <TableHeaderColumn>
                  <FormattedMessage id="statement_property" />
                </TableHeaderColumn>
                <TableRowColumn>{filial}</TableRowColumn>
              </TableRow>
              <TableRow>
                <TableHeaderColumn>
                  <FormattedMessage id="statement_range" />
                </TableHeaderColumn>
                <TableRowColumn>
                  <Range start={fecha1} end={fecha2} />
                </TableRowColumn>
              </TableRow>
              <TableRow>
                <TableHeaderColumn>
                  <FormattedMessage id="statement_currency" />
                </TableHeaderColumn>
                <TableRowColumn>{moneda}</TableRowColumn>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </header>
      <Table selectable={false} className="statement-table">
        <TableBody displayRowCheckbox={false}>
          <TableRow selectable={false}>
            <Header id="statement_type" width={50} />
            <Header id="statement_date" width={80} />
            <Header id="statement_concept" />
            <Header id="statement_debit" width={100} />
            <Header id="statement_credit" width={100} />
            <Header id="statement_balance" width={100} />
          </TableRow>
          {detalle.reverse().map((row, idx) => (
            <TableRow key={idx}>
              <TableRowColumn width={50}>{row.tipoMovimiento}</TableRowColumn>
              <TableRowColumn width={80}>
                <FormattedDate value={row.fechaRegistro} />
              </TableRowColumn>
              <TableRowColumn>
                {row.concepto}
                <br />
                <small>{row.servicio}</small>
              </TableRowColumn>
              <Money width={100} value={row.montoDebito} />
              <Money width={100} value={row.montoCredito} />
              <Money width={100} value={row.montoSaldo} />
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableRowColumn colSpan={3} style={{ textAlign: 'right' }}>
              <FormattedMessage id="totals" />
              <span>:</span>
            </TableRowColumn>

            <Money noElipses width={100} value={summary.debit} />
            <Money noElipses width={100} value={summary.credit} />
            <Money noElipses width={100} value={summary.balance} />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  )
}

const Statement = ({ condo, propertyId }) => {
  if (!condo) return <Loading />
  return (
    <Firebind
      path={`/propertyStatements/${condo.key}/${propertyId}/latest`}
      notFound={<FormattedMessage id="no_info" />}
    >
      <StatementDetail />
    </Firebind>
  )
}

export default withCondo(Statement)
