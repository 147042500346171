import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import renderHTML from 'react-render-html'
import { bindFirebasePath, unbindFirebasePath } from 'actions/fbActions'
import Firebind from 'components/Firebind'
import Loading from 'components/Loading'
import Panel from 'components/Panel'
import IsCondoAdmin from 'utils/IsCondoAdmin'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import EditIcon from 'material-ui-icons/Edit'

import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'
import Date from 'components/Date'

import { stylesheet } from 'styles'

const ArticleBody = ({ date, raw }) => (
  <div>
    <Date date={date} />
    {raw && renderHTML(stateToHTML(convertFromRaw(JSON.parse(raw))))}
  </div>
)

const ArticleGallery = ({ urls, condo }) => (
  <div style={stylesheet.gallery}>
    <ImageGallery
      showPlayButton={false}
      showFullscreenButton={false}
      items={urls.map(url => ({ original: url, thumbnail: url }))}
    />
  </div>
)

const Article = ({ articleKey, condo, firebindData }) => (
  <article style={stylesheet.newsContainer} className="news-item">
    <h1 style={stylesheet.newsHeader}>
      {!firebindData.published && (
        <span style={stylesheet.newsDraft}>
          <FormattedMessage id="article_draft" />{' '}
        </span>
      )}
      {firebindData.title}
      <IsCondoAdmin>
        <Link
          to={`/condo/${condo.slug}/news/${articleKey}`}
          style={stylesheet.newsEditButton}
        >
          <EditIcon
            style={stylesheet.editIcon}
            color={stylesheet.themeColors.secondary}
          />
        </Link>
      </IsCondoAdmin>
    </h1>
    <ArticleBody date={firebindData.publishDate} raw={firebindData.body} />
    {firebindData.gallery && firebindData.gallery.length && (
      <ArticleGallery condo={condo} urls={firebindData.gallery} />
    )}
  </article>
)

class ArticlesContainer extends React.Component {
  bindArticles(props = this.props) {
    const { condo, bind, state = 'published' } = props

    if (!condo) {
      return
    }

    if (state === 'all') {
      bind(
        `/articles/${condo.key}/content?orderByChild=timestamp&asArray=reverse`,
        'articles'
      )
    } else {
      bind(
        `/articles/${
          condo.key
        }/state/published?orderByChild=published&asArray=reverse`,
        'articles'
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    const { condo, unbind } = this.props
    const newCondo = nextProps.condo

    if (!newCondo) {
      return
    }

    if (condo && condo.key !== newCondo.key) {
      unbind(null, 'articles')
    }

    this.bindArticles(nextProps)
  }

  componentDidMount() {
    this.bindArticles()
  }

  componentWillUnmount() {
    const { unbind } = this.props
    unbind(null, 'articles')
  }

  render() {
    const {
      condo,
      tagFilter,
      firebase: {
        keys: { articles }
      }
    } = this.props

    if (!articles || articles.loading || !condo) {
      return <Loading />
    }

    return (
      <div style={stylesheet.articleItem}>
        {articles.value &&
          articles.value
            // Filter article with tag provided
            .filter(article => {
              if (!tagFilter) {
                return true
              }

              return article.tags.indexOf(tagFilter) >= 0
            })
            .map(article => (
              <Panel key={article.key} md={8}>
                <Firebind
                  path={`/articles/${condo.key}/content/${article.key}`}
                >
                  <Article condo={condo} articleKey={article.key} />
                </Firebind>
              </Panel>
            ))}
      </div>
    )
  }
}

export const Articles = connect(
  state => ({
    firebase: state.firebase
  }),
  dispatch => ({
    bind: bindActionCreators(bindFirebasePath, dispatch),
    unbind: bindActionCreators(unbindFirebasePath, dispatch)
  })
)(ArticlesContainer)
