import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Dropzone from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import { getCurrentCondo } from 'selectors/condos'
import { uploadFile } from 'actions/condoActions'
import { bindFirebasePath } from 'actions/fbActions'
import XLSX from 'xlsx'
import { Row, Col } from 'react-flexbox-grid'

import {
  FlatButton,
} from 'material-ui'

import Page from 'components/Page'
import Panel from 'components/Panel'

function fixdata(data) {
	var o = "", l = 0, w = 10240;
	for(; l<data.byteLength/w; ++l) o+=String.fromCharCode.apply(null,new Uint8Array(data.slice(l*w,l*w+w)));
	o+=String.fromCharCode.apply(null, new Uint8Array(data.slice(l*w)));
	return o;
}

function trimmed(row, key) {
  if (!row[key]) {
    return ''
  }

  return row[key].trim()
}

const FileReaderPromise = file => {
  return new Promise((yup, nope) => {
    const reader = new FileReader()
    reader.onload = ({target: {result}}) => {
      return yup(btoa(fixdata(result)))
    }
    reader.onerror = nope
    reader.readAsArrayBuffer(file)
  })
}

function validateEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

const validateRows = (rows) => {
  const isolatedRows = rows
    .reduce((ret, i) => {
      if (!i.contacts) {
        return ret
      }

      i.contacts.forEach(c => {
        ret.push({
          property: `${i.name}, ${i.propertyId}, ${i.registry}, ${i.registryId}`,
          status: c.email.trim() === '' ? 'warn' :
            !validateEmail(c.email) ? 'error' : 'good',
          ...c,
        })

      })

      return ret
    }, [])

  const warnings = isolatedRows.filter(row => row.status === 'warn')
  const errors = isolatedRows.filter(row => row.status === 'error')

  return { warnings, errors, isolatedRows }
}

class Preview extends React.Component {

  state = {
    // overwrite: true,
    complete: false,
    status: null,
  }

  importProperties = () => {
    const {
      refs: { condo, invites },
      rows,
      currentCondo,
      user,
    } = this.props

    // const { overwrite } = this.state
    this.setState({ status: 'Importing' })

    // TODO: Allow overwrite to be `false`, right now it just overwrites everything
    // const funcs = []
    condo.ref
      // First clear out the current condo properties
      .set(null)
      // Then push all properties in the import
      .then(() => Promise.all(rows.map(row => condo.ref.push({
        name: row.name,
        propertyId: row.propertyId,
        registry: row.registry,
        registryId: row.registryId,
        contacts: row.contacts,
      }))))
      // Then create all the invitations
      .then((refs) => Promise.all(refs.map(ref => ref.once('value')
        .then(snap => ({
          value: snap.val(),
          key: snap.key,
        }))
      )))
      // Create invite objects from the data in properties
      .then(items => items.reduce((ret, {value, key})=> {
        if (!value.contacts) {
          return ret
        }

        value.contacts
          .filter(i => i.email.trim() !== '')
          .forEach(i => ret.push({
            property: key,
            email: i.email,
            metadata: value,
          }))

        return ret
      }, []))
      // Push the invites
      .then(items => items.map(item => invites.ref.child(item.property).push({
        condo: currentCondo.key,
        condoName: currentCondo.name,
        condoSlug: currentCondo.slug,
        email: item.email,
        invitedBy: user.uid,
        property: item.property,
        status: 'pending',
        timestamp: Date.now(),
        metadata: {
          condo: currentCondo,
          property: item.metadata,
        },
      })))
      .then(refs => {
        console.log(refs);
        this.setState({ status: 'Invites sent Complete' })
      })
  }

  render() {
    const { rows } = this.props
    const { status } = this.state
    const { warnings, errors, isolatedRows} = validateRows(rows)

    return (
      <div>
        <table>
          <thead>
            <tr>
              <th><FormattedMessage id='property' /></th>
              <th><FormattedMessage id='contact' /></th>
              <th><FormattedMessage id='email' /></th>
              <th><FormattedMessage id='identification' /></th>
              <th><FormattedMessage id='phone' /></th>
            </tr>
            <tr>
              <th colSpan="5">
                {errors.length} <FormattedMessage id="errors" /> |
                {warnings.length} <FormattedMessage id="warnings" />
              </th>
            </tr>
          </thead>
          <tbody>
            {isolatedRows.length && isolatedRows
              .map(row => ({
                ...row,
                style: {
                  background: row.status === 'warn' ? 'orange' :
                    row.status === 'error' ? 'red' : '',
                  color: row.status === 'warn' ? 'white' :
                    row.status === 'error' ? 'white' : 'black',
                }
              }))
              .map((row, i) => (
                <tr key={i} style={row.style}>
                  <td>{row.property}</td>
                  <td>{row.name}</td>
                  <td>{row.email}</td>
                  <td>{row.identification}</td>
                  <td>{row.phone}</td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* <label><Checkbox
          onChange={(e) => { this.setState({overwrite: e.target.checked}) }}
          checked={this.state.overwrite} /> <FormattedMessage id="overwrite_all_properties" /></label>
        <br /> */}
        <FlatButton onClick={this.importProperties}><FormattedMessage id="import" /></FlatButton>
        {status && <strong>{status}</strong>}
      </div>
    )
  }
}

class UploadProperties extends React.Component {
  state = {
    data: null,
    bound: false,
  }

  onFilesDrop = files => {
    Promise
      // Get all files as promises
      .all(files.map(FileReaderPromise))
      // Turn them into workbooks
      .then(data => data.map( b => XLSX.read(b, { type: 'base64' })))
      // We're only need to worry about one at a time ... for now
      .then(wbs => wbs.length && wbs[0])
      // Get the data into rows
      .then(wb => wb.SheetNames.reduce((ret, sheetName)=> {
        const row = XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheetName])
        if (row.length) {
          ret.push(...row)
        }
        return ret
      }, []))
      // Prepare for mass submission
      .then(rows => rows.reduce((ret, row) => {
        row.trim = trimmed.bind(null, row)

        if (!row['NÚMERO FILIAL']) {
          return ret
        }
        const item = {
          name: `${row.trim('BLOQUE')}${row.trim('NÚMERO FILIAL')}`,
          propertyId: row.trim('NO. FINCA'),
          registry: row.trim('NOMBRE PROPIETARIO REGISTRAL'),
          registryId: row.trim('ID PROPIETARIO REGISTRAL'),
          contacts: [],
        }

        if (row['CORREO CONTACTO 1']) {
          item.contacts.push({
            name: row.trim('NOMBRE COMPLETO CONTACTO 1'),
            identification: row.trim('ID CONTACTO 1'),
            email: row.trim('CORREO CONTACTO 1'),
            phone: row.trim('TELÉFONO CONTACTO 1'),
          })
        }

        if (row['CORREO CONTACTO 2']) {
          item.contacts.push({
            name: row.trim('NOMBRE COMPLETO CONTACTO 2'),
            identification: row.trim('ID CONTACTO 2'),
            email: row.trim('CORREO CONTACTO 2'),
            phone: row.trim('TELÉFONO CONTACTO 2'),
          })
        }

        ret.push(item)
        return ret
      }, []))
      .then(rows => {
        this.setState({data: rows})
      })
  }

  componentWillReceiveProps(nextProps) {
    const { currentCondo, bind } = this.props
    if (!currentCondo || this.state.bound) {
      return
    }

    bind(`/properties/${currentCondo.key}`, 'condo')
    bind(`/invitations/${currentCondo.key}`, 'invites')
    this.setState({ bound: true })
  }

  render() {
    const {
      auth,
      currentCondo,
      importProperties,
      firebase: { keys },
    } = this.props

    const { data } = this.state

    return (
      <Page>
        <Row>
          <Col xs>
            <Panel heading={<FormattedMessage id="upload_in_bulk" />}>
              <Dropzone onDrop={this.onFilesDrop} width="100%" height="50px" style={{ padding: '2rem', border: '1px dashed #ccc'}}>
                <div><FormattedMessage id="drag_and_drop" /></div>
              </Dropzone>
              {data && <Preview
                user={auth.user}
                currentCondo={currentCondo}
                refs={keys}
                rows={data}
                importProperties={importProperties} />}
            </Panel>
          </Col>
        </Row>
      </Page>
    )
  }
}

export default connect(
  state => ({
    auth: state.auth,
    currentCondo: getCurrentCondo(state),
    firebase: state.firebase,
  }),
  dispatch => ({
    bind: bindActionCreators(bindFirebasePath, dispatch),
    uploadFile: bindActionCreators(uploadFile, dispatch),
  })
)(UploadProperties)
