import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import { bindFirebasePath, unbindFirebasePath } from 'actions/fbActions'
import { saveVehicle, initProperty } from 'actions/properties'

import Page from 'components/Page'
import Panel from 'components/Panel'
import Loading from 'components/Loading'

import { Row, Col } from 'react-flexbox-grid'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import { Details } from 'components/Properties/PropertyDetails'
import VehicleForm from 'components/Properties/VehicleForm'

export const InitRedirect = ({ property, condo }) => <Redirect to={`/init/${condo}/${property}`} />

class PropertyInitContainer extends React.Component {
  getKeys() {
    const { match: { params: { condoId, propertyId } } } = this.props

    return { condoId, propertyId }
  }

  getPaths() {
    const { condoId, propertyId } = this.getKeys()
    return [
      `/properties/${condoId}/${propertyId}`,
      `/condominiums/${condoId}`,
    ]
  }

  bind(props = this.props) {
    const { bind } = props
    const [ propertyPath, condoPath ] = this.getPaths()
    bind(condoPath, 'condo')
    bind(propertyPath, 'property', true)
  }

  componentWillReceiveProps(nextProps) {
    const {
      unbind,
      match: { params: { condoId, propertyId } },
    } = this.props

    const newCondoId = nextProps.match.params.condoId
    const newPropertyId = nextProps.match.params.propertyId

    if (condoId !== newCondoId || propertyId !== newPropertyId) {
      unbind(null, 'condo')
      unbind(null, 'property')
    }

    this.bind(nextProps)
  }

  componentDidMount() {
    this.bind()
  }

  componentWillUnmount() {
    const { unbind } = this.props
    unbind(null, 'condo')
    unbind(null, 'property')
  }

  onSubmit = (data, ...other) => {
    const { condoId, propertyId } = this.getKeys()
    const { initProperty, saveVehicle } = this.props

    initProperty(condoId, propertyId, () => {
      saveVehicle({key: condoId}, {key: propertyId}, data)
    })
  }

  render() {
    const {
      properties,
      form,
      firebase: { keys: { condo, property } },
    } = this.props

    if (!property || property.loading) {
      return <Loading />
    }
    if (!condo || condo.loading) {
      return <Loading />
    }

    const [ propertyPath ] = this.getPaths()

    if (properties.saved && property.value.init) {
      return <Redirect to={`/dashboard`} />
    }

    const includeCarAccess = condo.value && condo.value.settings && condo.value.settings.validateAccess

    return (
      <Page disabled={properties.saving} title={<span><FormattedMessage id="property"/> {property.value.name}</span>}>
        <Details path={propertyPath} />
        <Panel>
          <VehicleForm
            inset={false}
            onSubmit={this.onSubmit}
            initialValues={{ type: 'car' }}
            requireAll={includeCarAccess}
            helpTextTop={(
              <Row style={{ alignItems: 'center' }}>
                <Col xs={10}>
                  <p style={{ fontWeight: 200 }}>
                    <FormattedHTMLMessage id="accept_invite" />
                    {includeCarAccess && <FormattedHTMLMessage id="accept_invite_quickpass" />}
                  </p>
                </Col>
              </Row>
            )}
            isValid={form && form.values && form.values.accept_tos}
            validateForm={true}
            helpTextBottom={<FormattedHTMLMessage id="accept_tos" />} />
          </Panel>
      </Page>
    )
  }
}

export default connect(
  state => ({
    firebase: state.firebase,
    properties: state.properties,
    form: state.form.VehicleForm,
  }),
  dispatch => ({
    bind: bindActionCreators(bindFirebasePath, dispatch),
    unbind: bindActionCreators(unbindFirebasePath, dispatch),
    saveVehicle: bindActionCreators(saveVehicle, dispatch),
    initProperty: bindActionCreators(initProperty, dispatch),
  })
)(PropertyInitContainer)
