export default {
  // Auth
  auth_display_name: 'Nombre a mostrar',
  auth_phone_number: 'Número de teléfono',
  auth_email: 'Correo',
  auth_password: 'Contraseña',
  auth_password_confirm: 'Confirmar Contraseña',
  auth_login_header: 'Ingresar',
  auth_signup_header: 'Registrarse',
  auth_login_link: 'Ingresar con usuario',

  menu_home: 'Inicio',
  menu_news: 'Noticias',
  menu_files: 'Archivos',
  menu_properties: 'Filiales',
  menu_my_property: 'Mi Filial',
  menu_statement: 'Estado de Cuenta',

  coming_soon: 'Próximamente ...',
  folder_name: 'Carpeta',
  no_files: 'No hay archivos',

  // Auth Errors
  'auth/user-not-found': 'Usuario no encontrado',
  'auth/email-already-in-use': 'El correo ya se encuentra en uso',
  'auth/wrong-password': 'Credenciales incorrectas',
  'auth/weak-password': 'La contraseña debe tener al menos 6 caracteres',

  auth_logout_confirm: '¿Seguro que quieres cerrar la sesión?',
  auth_logout_dialog_title: 'Todo Listo?',
  auth_logout_cancel: 'No, permanecer conectado',
  auth_profile_updated: 'Perfil Actualizado',
  auth_password_recover:
    'Se ha enviado un correo electrónico para recuperar su contraseña.',
  passwords_do_not_match: 'Las contraseñas enviadas no coinciden',

  property_vehicles: 'Vehículos',
  property_contacts: 'Contactos',
  property_id: 'Identificación de la propiedad',
  property_registry: 'Registro',
  property_registry_id: 'Identificación de el Registro',
  property_details: 'Detalles',
  property_users: 'Usarios',

  vehicle_type: 'Tipo',
  vehicle_type_motorcycle: 'Motocicleta',
  vehicle_type_car: 'Carro',
  vehicle_year: 'Año',
  vehicle_make: 'Marca del vehículo',
  vehicle_model: 'Modelo',
  vehicle_license: 'Placa',
  vehicle_access: 'No. Quickpass',

  contact_identification: 'Identificación',
  contact_name: 'Nombre completo',
  contact_email: 'Email',
  contact_phone: 'Teléfono',

  property: 'Filial',
  contact: 'Contacto',
  email: 'Correo',
  phone: 'Teléfono',
  identification: 'Identificación',

  errors: 'Errores',
  warnings: 'Advertencias',
  import: 'Importar',
  actions: 'Acciones',

  your_condos: 'Sus condominios',
  new_article: 'Artículo nuevo',
  article_draft: 'Borrador',
  article_title: 'Título del artículo',
  article_content: 'Contenido del artículo',
  article_tags: 'Etiquetas del artículo',
  article_publish_date: 'Fecha de publicación',
  article_publish_time: 'Hora de publicación',
  article_gallery: 'Galería imágenes',
  articles_all: 'TODO',
  manage_administrator: 'Control de administradores',
  upload_properties: 'Cargar filiales',
  manage_settings: 'Ajustes',
  reset_password: 'Restablecer contraseña',
  delete_user: 'Eliminar Usario',
  upload_in_bulk: 'Cargar filiales en grupo',
  drag_and_drop: 'Arrastre y suelte archivos aquí o haga clic para seleccionar',
  overwrite_all_properties: 'Sobrescribir todas las filiales',
  invite_user_to_manage_property:
    'Invitar a un usuario a gestionar esta filial',
  invite_not_found: 'No se encontró la invitación',
  invitation_resent: 'La invitación ha sido reenviada',
  user_deleted: 'El usario esta eliminado',
  // Statuses
  pending: 'Pendiente',

  email_invite_header: '¡Has recibido una invitación a MiCondo!',
  email_invite_copy:
    'Has sido invitado a administrar una propiedad en {condo}.  Acepte la invitación a continuación.',
  email_invite_body:
    'Aceptar la invitación para convertirse en un administrador de la propiedad en MiCondo',
  email_invite_login:
    'Para aceptar esta invitación debe iniciar sesión o registrarse.',
  email_invite_button: 'Aceptar',

  email_news_header: 'Hay una nueva notificación',
  email_news_body: '',
  email_news_button: 'Leer',

  panel_title_files: 'Archivos',
  panel_title_properties: 'Filiales',

  page_title_profile: 'Perfil',

  accept_invite:
    'Por favor, Ingrese la información de su vehículo antes de usar esta plataforma.  ',
  accept_invite_quickpass:
    '<b>Recuerde incluir los números reales de su QuickPass.</b>',
  accept_tos:
    'He leído y acepto los <a href="/tos" target="_blank" style="text-decoration: underline;">Usos y Condiciones</a> del Sitio Web MiCondoCR',
  tos_header: 'Usos y Condiciones Sitio Web MiCondoCR',

  saved: 'Guardado',

  // Button Text
  loading: 'Cargando',
  submit: 'Ingresar',
  save: 'Guardar',
  saving: 'Guardando',
  search: 'Buscar',
  publish: 'Publicar',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  create: 'Crear',
  edit: 'Editar',
  menu: 'Menú',
  home: 'Inicio',
  logout: 'Cerrar sesión',
  invite: 'Invitación',
  delete: 'Borrar',

  balance: 'Saldo',
  calculating: 'Calculando',
  totals: 'Totales',
  last_updated: 'Actualizado',

  statement_generate: 'Generar Estados',
  statement_generate_send: 'Generar y Enviar Estados',

  statement_condo: 'Condominio',
  statement_owner: 'Condomino',
  statement_range: 'Rango Fecha',
  statement_currency: 'Moneda',
  statement_property: 'Lote',

  statement_concept: 'CONCEPTO',
  statement_date: 'FECHA',
  statement_credit: 'CRÉDITOS',
  statement_debit: 'DÉBITOS',
  statement_balance: 'SALDO',
  statement_service: 'METODO PAGO',
  statement_type: 'TIPO DOC',

  no_info:
    'Información no está desponible, por favor generar y inténtelo de nuevo.'
}
