import React from 'react'
import { Route } from 'react-router-dom'

import Lock from './Lock'
import Login from './Login'
import SignUp from './SignUp'
import Invite from './Invite'
import { ResetPassword, RecoverPassword } from './ResetPassword'

import { stylesheet } from 'styles'
import MiCondoLogo from 'components/Logo'

const AuthLayout = props => (
  <div id="body">
    <div>
      {' '}
      {/* container gutter={24} justify="center"> */}
      <div style={stylesheet.flexComponent}>
        {' '}
        {/* item xs={8}> */}
        <MiCondoLogo />
        <Route path="/auth/invite/:invite" component={Invite} />
        <Route path="/auth/lock" component={Lock} />
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/signup" component={SignUp} />
        <Route path="/auth/reset-password" component={ResetPassword} />
        <Route path="/auth/recover" component={RecoverPassword} />
      </div>
    </div>
  </div>
)

export default AuthLayout
