import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { signup } from 'actions/authActions'
import ReturnUrlRedirect from 'utils/ReturnUrlRedirect'
import Loading from 'components/Loading'

import SignUpForm from './Component'

class SignUpContainer extends React.Component {
  handleSignUp = (signUpData) => {
    return this.props.signup(signUpData)
  }

  render() {
    const {
      auth,
    } = this.props

    if (auth === undefined) {
      return <Loading />
    }

    if (auth.user) {
      return <ReturnUrlRedirect to={'/dashboard'} />
    }

    return (
      <div>
        {auth.error && <FormattedMessage id={auth.error.code} />}
        <SignUpForm
          user={auth.user}
          onSubmit={this.handleSignUp}/>
      </div>
    )
  }
}

export default connect(
  state => ({
    auth: state.auth
  }),
  dispatch => ({
    signup: bindActionCreators(signup, dispatch),
  })
)(SignUpContainer)
