import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getCurrentCondo } from 'selectors/condos'
import { isCondoAdmin } from 'selectors/condos'
import _get from 'lodash/get'

export const withCondoAdmin = WrappedComponent => {
  return connect(state => ({
    isCondoAdmin: isCondoAdmin(state)
  }))(WrappedComponent)
}

export const withCondo = WrappedComponent => {
  return connect(state => ({
    condo: getCurrentCondo(state)
  }))(WrappedComponent)
}

export const withRedux = (getState, actions) => WrappedComponent => {
  return connect(
    state => {
      return Object.keys(getState).reduce((ret, key) => {
        const get = getState[key]
        if (typeof get === 'string') {
          ret[key] = _get(state, get)
        } else {
          ret[key] = get(state)
        }
        return ret
      }, {})
    },
    actions &&
      (dispatch => {
        return Object.keys(actions).reduce((ret, key) => {
          ret[key] = bindActionCreators(actions[key], dispatch)
          return ret
        }, {})
      })
  )(WrappedComponent)
}
