import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js'
import { Field } from 'redux-form'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

class ControlledEditor extends React.Component {
  state = {
    editorState: EditorState.createEmpty(),
  }

  handleEditorStateChange = editorState => {
    const { onChange } = this.props.input
    const raw = convertToRaw(editorState.getCurrentContent())
    this.setState({ editorState })
    onChange(JSON.stringify(raw))
  }

  componentDidMount() {
    const { input: { value } } = this.props
    let raw = null
    try {
      raw = JSON.parse(value)
    } catch (e) {
      return
    }
    const editorState = EditorState.createWithContent(convertFromRaw(raw))
    this.setState({ editorState })
  }

  render() {

    return (
      <Editor
        editorState={this.state.editorState}
        onEditorStateChange={this.handleEditorStateChange} />
    )
  }
}

export default ({name, label, required, ...props}) => {
  return (
    <Field
      component={ControlledEditor}
      name={name}
      required={required}
      />
  )
}
