
const initialState = {
  current: null,
  condos: []
}

const condosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONDO_SET_CONDOS":
      return {
        ...state,
        condos: action.condos,
      }

    case "CONDO_SET_CURRENT_SLUG":
      return {
        ...state,
        current: action.slug
      }

    case "CONDO_CLEAR_CURRENT":
      return {
        ...state,
        current: null
      }
    case "CONDO_ERROR":
      return {
        ...state,
        current: null,
      }

    default:
      return state
  }
}


export default condosReducer
