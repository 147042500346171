import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { addLocaleData, IntlProvider } from 'react-intl'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'

import { MuiThemeProvider } from 'material-ui/styles'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { start } from 'actions/authActions'
import { init } from 'actions/condoActions'

import messages from 'messages'
import { muiTheme } from 'styles'
import { getLocale } from 'utils/locale'

// Local data
;[en, es].forEach(addLocaleData)

const locale = getLocale()

class AppContainer extends React.Component {
  static propTypes = {
    routes: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  }

  componentDidMount() {
    if (this.props.start) {
      this.props.start()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.auth.user && nextProps.auth.user) {
      this.props.init()
    }
  }

  render() {
    const { routes, store, auth } = this.props

    return (
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages(locale)}>
          <MuiThemeProvider muiTheme={muiTheme}>
            <div style={{ height: '100%' }}>
              <Router>{auth.loaded && routes}</Router>
            </div>
          </MuiThemeProvider>
        </IntlProvider>
      </Provider>
    )
  }
}

export default connect(
  state => ({
    auth: state.auth
  }),
  dispatch => ({
    start: bindActionCreators(start, dispatch),
    init: bindActionCreators(init, dispatch)
  })
)(AppContainer)
