import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import InputField from 'components/InputField'
import EditorField from 'components/EditorField'
import GalleryField from 'components/GalleryField'
import ChipField from 'material-ui-chip-input'

import {
  RaisedButton,
  Paper,
} from 'material-ui'

const renderChip = (tags) => {
  return ({input, hintText, floatingLabelText}) => {
    return (
      <ChipField
        {...input}
        value = { input.value || []}
        dataSource={tags}
        onRequestAdd={(addedChip) => {
          let values = input.value || []
          values = values.slice()
          values.push(addedChip)
          input.onChange(values)
        }}
        onRequestDelete={(deletedChip) => {
          let values = input.value || []
          values = values.filter(v => v !== deletedChip)
          input.onChange(values)
        }}
        onBlur={() => input.onBlur()}
        hintText={hintText}
        floatingLabelText={floatingLabelText}
      />
    )
  }
}

class Article extends React.Component {
  submitArticle = (published = false) => {
    const { handleSubmit, onSubmit } = this.props

    return handleSubmit(values => {
      onSubmit({
        ...values,
        published,
      })
    })
  }


  render() {

    const style = {
      buttons: { margin: 5 },
      container: { margin: 10 }
    }

    const {
      submitting,
      initialValues: { key },
      intl: { formatMessage },
      tags = [],
    } = this.props

    return (
      <Paper style={{padding: '2em'}}>
        <div>
          <InputField
            name="title"
            type="text"
            required={true}
            placeholder="Article Title"
            label={formatMessage({id: 'article_title'})}
            style={{fontSize: '20px', width: '100%'}}
            />
        </div>
        <div>
          <EditorField
            name="body"
            required={true}
            label={formatMessage({id: 'article_content'})}
            />
        </div>
        <div style={{ display: 'flex'}}>
          <InputField
            name="publishDate"
            type="date"
            label={formatMessage({id: 'article_publish_date'})}
            style={{flex: 1}}
            />
          <InputField
            name="publishTime"
            type="time"
            label={formatMessage({id: 'article_publish_time'})}
            style={{flex: 1}}
            />
        </div>
        <div>
          <GalleryField
            name="gallery"
            label={formatMessage({id: 'article_gallery'})}
            />
        </div>
        <div>
          <Field
            name="tags"
            component={renderChip(tags)}
            floatingLabelText={formatMessage({id: 'article_tags'})}
            style={{width: '100%'}} />
        </div>
        <RaisedButton style={style.buttons} primary={true} disabled={submitting} onClick={this.submitArticle()}><FormattedMessage id="save" /></RaisedButton>
        <RaisedButton style={style.buttons} secondary={true} buttonStyle={{ color: '#FFFFFF' }} disabled={submitting} onClick={this.submitArticle(true)}><FormattedMessage id="publish" /></RaisedButton>
        {key && <RaisedButton style={style.buttons}  secondary={true} buttonStyle={{ color: '#FFFFFF' }} disabled={submitting} onClick={this.submitArticle('deleted')}><FormattedMessage id="delete" /></RaisedButton>}
      </Paper>
    )
  }
}

export default reduxForm({
  form: 'ArticleForm',
})(injectIntl(Article))
