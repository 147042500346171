import React from 'react'
import './styles.css'
import { Link } from 'react-router-dom'
import { withCondo, withRedux } from 'utils'
import { FormattedMessage } from 'react-intl'
import { getBalances } from 'selectors/properties'
import Currency from 'components/Currency'

const PropertyBalance = ({ balances, linkTo }) => {
  return (
    <Link to={linkTo}>
      <div className="property-balance">
        <small className="property-balance-label">
          {balances === 'loading' ? (
            <FormattedMessage id="calculating" />
          ) : (
            <FormattedMessage id="balance" />
          )}
        </small>
        <span className="property-balance-amount">
          {balances !== 'loading' && <Currency value={balances.balance} />}
          {balances === 'loading' && '...'}
        </span>
      </div>
    </Link>
  )
}

export default withRedux({
  balances: getBalances
})(withCondo(PropertyBalance))
