import React from 'react'


export default class Lock extends React.Component {
  render() {
    return (
      <div>Lock</div>
    )
  }
}
