import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  CircularProgress,
  List,
  ListItem,
} from 'material-ui'
import Typography from 'components/Typography'
import { stylesheet } from '../../styles'

const CondoPicker = ({items, wrapper = ListItem})=> {
  if (!items || !items.length) {
    return <CircularProgress size={15}/>
  }

  const Wrapper = wrapper || ListItem

  if (items.length <= 1) {
    return null
  }

  return (
    <List>
      <div style={stylesheet.menuLabel}>
        <Typography align="right" type="display">
          <FormattedMessage id="your_condos" />
        </Typography>
      </div>
      {items && items.map(condo => (
        <Link to={`/condo/${condo.slug}`} key={condo.key}>
          {Wrapper.propTypes.wrapper && (<Wrapper wrapper={ListItem}>{condo.name}</Wrapper>)}
          {!Wrapper.propTypes.wrapper && (<ListItem primaryText={condo.name} />)}
        </Link>
      ))}
    </List>
  )
}

export default connect(
  state => ({ items: state.condos.condos })
)(CondoPicker)
