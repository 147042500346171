import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { database, storage } from 'utils/firebase'
import { getCurrentCondo } from 'selectors/condos'
import { bindFirebasePath, unbindFirebasePath } from 'actions/fbActions'
import moment from 'moment'
import Loading from 'components/Loading'
import DialogButton from 'components/DialogButton'
import IsCondoAdmin from 'utils/IsCondoAdmin'

import {
  FlatButton,
  IconButton,
  TextField,
  CircularProgress
} from 'material-ui'
import CreateFolderIcon from 'material-ui-icons/CreateNewFolder'
import FileDownloadIcon from 'material-ui-icons/FileDownload'
import FolderIcon from 'material-ui-icons/Folder'
import HomeIcon from 'material-ui-icons/Home'
import DeleteIcon from 'material-ui-icons/Delete'
import UploadIcon from 'material-ui-icons/CloudUpload'

import Dropzone from 'react-dropzone'

import { stylesheet } from 'styles'

const PathFiles = ({ folderClick, deleteFile, path }) => {
  const folders = Object.keys(path)
    .filter(path => path !== '__files')
    .filter(path => path !== '__name')

  return (
    <div style={stylesheet.files.content}>
      {folders.map(key => (
        <div
          key={key}
          style={stylesheet.files.folder}
          onClick={_ => {
            folderClick(key)
          }}
        >
          <FolderIcon
            color={stylesheet.themeColors.gray}
            style={stylesheet.files.icon(20)}
          />
          <span style={{ flex: 1, paddingLeft: '5px' }}>{key}</span>
        </div>
      ))}
      {path.__files &&
        path.__files.map(file => (
          <div key={file.name} style={{ display: 'flex', padding: '10px' }}>
            <a
              href={file.url}
              target="_blank"
              style={{
                flex: 1,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {file.name}
            </a>
            <a
              href={file.url}
              download={file.name}
              style={{
                flex: 1,
                flexGrow: 0
              }}
            >
              <IconButton>
                <FileDownloadIcon style={stylesheet.files.icon(20)} />
              </IconButton>
            </a>
            <IsCondoAdmin>
              <IconButton onClick={_ => deleteFile(file)}>
                <DeleteIcon style={stylesheet.files.icon(20)} />
              </IconButton>
            </IsCondoAdmin>
          </div>
        ))}
      {(!path.__files || path.__files.length === 0) && folders.length === 0 && (
        <small style={stylesheet.files.noFiles}>
          <FormattedMessage id="no_files" />
        </small>
      )}
    </div>
  )
}

const PathBreadcrumbs = ({ currentPath, onClick }) => (
  <div style={stylesheet.files.breadcrumbs}>
    <HomeIcon onClick={_ => onClick()} style={stylesheet.files.homeIcon} />
    <span style={stylesheet.files.currentPath}>{currentPath}</span>
  </div>
)

class CondoFilesContainer extends React.Component {
  state = {
    currentPath: '',
    folderName: null,
    uploadError: null,
    uploading: false
  }

  bindFiles = (props = this.props) => {
    const { condo, bind } = props

    if (!condo) {
      return
    }

    bind(`files/${condo.key}`, 'files', true)
  }

  componentDidMount() {
    this.bindFiles()
  }

  componentWillReceiveProps(nextProps) {
    const { condo, unbind } = this.props
    const newCondo = nextProps.condo

    if (!newCondo) {
      return
    }

    if (condo && condo.key !== newCondo.key) {
      unbind(null, 'files')
    }

    this.bindFiles(nextProps)
  }

  componentWillUnmount() {
    const { unbind } = this.props
    unbind(null, 'files')
  }

  onDrop = files => {
    const { condo } = this.props
    const { uploading } = this.state

    // Don't try to upload if we're already uploading something
    if (uploading) {
      return this.setState({
        uploadError: 'condo_file_uploading'
      })
    }

    // Prepare the current state
    this.setState({
      uploadError: null,
      uploading: true
    })

    const today = moment().format('YYYYMMDD')

    // Upload files
    Promise.all(
      files.map(file => {
        const ref = storage.ref(
          `uploads/${condo.key}/files/${today}/${file.name}`
        )
        return ref.put(file)
      })
    )
      // Get the file payloads
      .then(snapshots => snapshots.map(snapshot => snapshot.metadata))
      .then(metas =>
        metas.map(meta => ({
          name: meta.name,
          url: meta.downloadURLs[0],
          contentType: meta.contentType,
          size: meta.size,
          timeCreated: meta.timeCreated,
          type: meta.type,
          updated: meta.updated
        }))
      )
      // Update database
      .then(this.uploadToCurrentPath)
      .then(_ => {
        this.setState({
          uploadError: null,
          uploading: false
        })
      })
      // If there's something wrong, then we handle it
      .catch(e => {
        this.setState({
          uploadError: 'condo_file_upload_error'
        })
      })
  }

  /**
   * Creating a folder in the current path
   * @return null
   */
  createFolder = _ => {
    // Creating a folder is as simple as adding a property to
    // the current property
    const { currentPath, folderName } = this.state
    const { condo } = this.props
    this.setState({ folderName: null })

    const payload = {}
    payload[folderName] = {
      __name: folderName,
      __files: []
    }

    const ref = database.ref(`files/${condo.key}/${currentPath}`)
    return ref.update(payload)
  }

  /**
   * Updates the database with the new files
   * @param  {[uploads]} uploads Array of uploaded files
   * @return Promise
   */
  uploadToCurrentPath = (uploads = [], remove) => {
    const {
      condo,
      firebase: {
        keys: { files }
      }
    } = this.props
    const { currentPath } = this.state

    let current = files.value

    // If we're not at the root, we have to dig through the object
    // to find our current location
    if (currentPath !== '') {
      const paths = currentPath.split('/')
      paths.splice(-1)

      paths.forEach(path => {
        current = current[path]
      })
    }

    // Instantiate if it doesn't exist
    current.__files = current.__files || []

    if (remove && remove.length) {
      current.__files = current.__files.filter(
        file => !remove.find(f => f.url === file.url)
      )
    }

    // Update the database
    const ref = database.ref(`files/${condo.key}/${currentPath}/__files`)
    return ref.set([...current.__files, ...uploads]).catch(error => {
      console.log('UPLAOD ERROR', error)
    })
  }

  /**
   * Deletes a file from the current working directory
   * @param  {file} file The file to delete
   * @return {null}
   */
  deleteFile = file => {
    // TODO: Commenting this out for now to have a way to retrieve
    // deleted files
    //
    // const ref = storage.refFromURL(file.url)
    // ref.delete()
    //   .then(_ => {
    this.uploadToCurrentPath([], [file])
    // })
    // .catch(console.log)
  }

  /**
   * Sets the current path of the view
   * @param {String} [folder=null] Current path in the files object
   */
  setCurrentPath = (folder = null) => {
    // If we're at root, then set to an empty string
    if (folder === null) {
      return this.setState({
        currentPath: ''
      })
    }

    const { currentPath } = this.state
    this.setState({
      currentPath: `${currentPath}${folder}/`
    })
  }

  /**
   * Returns the contents of the current path
   * @param  {[Files]} files  The contents of the current path
   * @return {[Files]}
   */
  getVisibleFolder(files) {
    // We need to use the path and show the contents of the selected path
    const { currentPath } = this.state

    if (currentPath === '') {
      return files
    }

    const paths = currentPath.split('/')
    paths.splice(-1)

    // Loop through the current path to get the correct property
    let ret = files
    paths.forEach(path => {
      ret = ret[path]
    })

    return ret
  }

  /**
   * Render the component
   * @return null
   */
  render() {
    const {
      firebase: {
        keys: { files }
      }
    } = this.props
    const { currentPath, uploading } = this.state

    if (!files || files.loading) {
      return <Loading />
    }

    const visible = this.getVisibleFolder(files.value)

    return (
      <div>
        <PathBreadcrumbs
          onClick={this.setCurrentPath}
          currentPath={currentPath}
        />
        {visible && (
          <PathFiles
            path={visible}
            folderClick={this.setCurrentPath}
            deleteFile={this.deleteFile}
          />
        )}

        <IsCondoAdmin>
          <div style={stylesheet.files.iconsManager}>
            <DialogButton
              title={
                <p>
                  <FormattedMessage id="folder_name" />
                </p>
              }
              launchButton={<CreateFolderIcon />}
              actionButton={
                <FlatButton onClick={this.createFolder}>
                  <FormattedMessage id="create" />
                </FlatButton>
              }
            >
              <TextField
                name="folderName"
                style={{ width: '100%' }}
                onChange={e => {
                  this.setState({ folderName: e.target.value })
                }}
              />
            </DialogButton>
            <Dropzone
              onDrop={this.onDrop}
              disabled={uploading}
              style={{
                width: 'auto',
                height: 'auto'
              }}
            >
              {!uploading && <UploadIcon />}
              {uploading && <CircularProgress thickness={2} size={18} />}
            </Dropzone>
          </div>
        </IsCondoAdmin>
      </div>
    )
  }
}

export default connect(
  state => ({
    condo: getCurrentCondo(state),
    firebase: state.firebase
  }),
  dispatch => ({
    bind: bindActionCreators(bindFirebasePath, dispatch),
    unbind: bindActionCreators(unbindFirebasePath, dispatch)
  })
)(CondoFilesContainer)
