import React from 'react'
import { reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import Panel from 'components/Panel'

import { RaisedButton } from 'material-ui'

import InputField from 'components/InputField'
import { stylesheet } from 'styles'

// {!user && (<h1><FormattedMessage id="auth_login_header" /></h1>)}
const Login = ({
  user,
  handleSubmit,
  submitting,
  submitError,
  intl: { formatMessage }
}) => (
  <Panel heading={<FormattedMessage id="auth_login_header" />}>
    {submitError && (
      <div style={stylesheet.errorPanel}>
        <FormattedMessage id={submitError.code} />
      </div>
    )}
    <form onSubmit={handleSubmit}>
      <div>
        <InputField
          name="email"
          type="email"
          required={true}
          label={formatMessage({ id: 'auth_email' })}
        />
      </div>
      <div>
        <InputField
          name="password"
          type="password"
          required={true}
          label={formatMessage({ id: 'auth_password' })}
        />
      </div>

      <RaisedButton
        primary={true}
        type="submit"
        fullWidth={true}
        disabled={submitting}
      >
        <FormattedMessage id="submit" />
      </RaisedButton>
    </form>

    <Link to="/auth/signup" style={stylesheet.authLink}>
      <FormattedMessage id="auth_signup_header" />
    </Link>
  </Panel>
)

export default reduxForm({
  form: 'LoginForm'
})(injectIntl(Login))
