import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { saveContact, deleteItem } from 'actions/properties'
import { bindFirebasePath, unbindFirebasePath } from 'actions/fbActions'
// import { getCurrentCondo } from 'selectors/condos'
// import { getCurrentProperty } from 'selectors/properties'
//
import Loading from 'components/Loading'
import Panel from 'components/Panel'
import { withCondoAdmin } from 'utils/IsCondoAdmin'


import ContactForm from 'components/Properties/ContactForm'

import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableRowColumn,
  // Grid,
} from 'material-ui'

import IconDelete from 'material-ui-icons/Delete'
import EditIcon from 'material-ui-icons/Edit'

class ContactsContainer extends React.Component {
  state = {
    current: null,
    showForm: false,
  }

  bindContacts(props = this.props) {
    const { condo, bind, property } = props

    if (!condo) {
      return
    }

    bind(`/properties/${condo.key}/${property.key}/contacts?asArray=true`, 'contacts', true)
  }

  componentWillReceiveProps(nextProps) {
    const {
      condo,
      property,
      unbind,
    } = this.props

    const newCondo = nextProps.condo
    const newProperty = nextProps.property

    if (!newCondo || !newProperty) {
      return
    }

    if ((condo && condo.key !== newCondo.key) ||
      (property && property.key !== newProperty.key)) {
        unbind(null, 'contacts')
    }

    this.bindContacts(nextProps)
  }

  componentDidMount() {
    this.bindContacts()
  }

  componentWillUnmount() {
    const { unbind } = this.props
    unbind(null, 'contacts')
  }

  toggleForm = _ => {
    this.setState({
      showForm: !this.state.showForm,
      current: null,
    })
  }

  selectContact = current => {
    const { firebase: { keys: { contacts }}} = this.props

    this.setState({
      shwoForm: true,
      current: contacts.value[current],
    })
  }

  onSubmit = contact => {
    const { condo, property, saveContact } = this.props
    saveContact(condo, property, contact, this.state.current && this.state.current.key)
    this.setState({ current: false })
  }

  onDelete = contact => {
    const { condo, property, deleteContact } = this.props
    deleteContact(condo, property, contact)
    this.setState({ current: false })
  }

  render() {
    const {
      firebase: { keys: { contacts } },
      isCondoAdmin,
    } = this.props

    const { current, showForm } = this.state

    if (!contacts || contacts.loading) {
      return <Loading />
    }

    return (
      <Panel
        heading={<FormattedMessage id='property_contacts' />}
        button={<EditIcon onClick={this.toggleForm} />}>
        {contacts.value && (
          <ContactsList
            isCondoAdmin={isCondoAdmin}
            data={contacts.value}
            onSelect={this.selectContact}
            onDelete={this.onDelete}
          />
        )}
        {showForm && <ContactForm
          onSubmit={this.onSubmit}
          enableReinitialize={true}
          initialValues={current || {}} />}
      </Panel>
    )
  }
}

const ContactsList = ({ isCondoAdmin, onDelete, data, onSelect }) => (
  <Table onRowSelection={rows => onSelect(rows[0]) }>
    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
      <TableRow>
        <TableRowColumn><FormattedMessage id='contact_name' /></TableRowColumn>
        <TableRowColumn><FormattedMessage id='contact_identification' /></TableRowColumn>
        <TableRowColumn><FormattedMessage id='contact_email' /></TableRowColumn>
        <TableRowColumn><FormattedMessage id='contact_phone' /></TableRowColumn>
        {isCondoAdmin && <TableRowColumn />}
      </TableRow>
    </TableHeader>
    <TableBody displayRowCheckbox={false}>
      {data.map((contact, idx) => (
        <TableRow key={idx}>
          <TableRowColumn>{contact.name}</TableRowColumn>
          <TableRowColumn>{contact.identification}</TableRowColumn>
          <TableRowColumn>{contact.email}</TableRowColumn>
          <TableRowColumn>{contact.phone}</TableRowColumn>
          {isCondoAdmin && (
            <TableRowColumn>
              <IconDelete onClick={() => onDelete(contact, idx)} style={{ cursor: 'pointer' }} />
            </TableRowColumn>
          )}
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

export default connect(
  state => ({
    firebase: state.firebase,
  }),
  dispatch => ({
    saveContact: bindActionCreators(saveContact, dispatch),
    deleteContact: bindActionCreators(deleteItem('contacts'), dispatch),
    bind: bindActionCreators(bindFirebasePath, dispatch),
    unbind: bindActionCreators(unbindFirebasePath, dispatch),
  })
)(withCondoAdmin(ContactsContainer))
