const initialState = {
  error: null,
  loading: false,
  saved: false,
}

const articleReducer = (state = initialState, action)=> {
  switch (action.type) {
    case "ARTICLE_SAVED":
      return {
        ...state,
        saved: true,
      }
    case "ARTICLE_ERROR":
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}

export default articleReducer
