import React from 'react'
import { Field } from 'redux-form'

import SelectField from 'material-ui/SelectField'

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom,
}) => (
  <SelectField
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    onChange={(event, index, value) => input.onChange(value)}
    children={children}
    {...custom}
  />
)

const OptionField = ({type, name, label, style, children, required}) => (
  <Field
    name={name}
    label={label}
    style={style}
    required={required}
    component={renderSelectField}
    >{children}</Field>
)

export default OptionField
