import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'
import createStore from './store/createStore'

import routes from './routes'

import './index.css'
import './media.css'

import injectTapEventPlugin from 'react-tap-event-plugin'
// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
injectTapEventPlugin()

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the routerKey "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.
const initialState = window.___INITIAL_STATE__
const store = createStore(initialState)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')

let render = Component => {
  const _routes = routes(store)

  ReactDOM.render(<Component store={store} routes={_routes} />, MOUNT_NODE)
}
// ========================================================
// Go!
// ========================================================
render(App)

// if (module.hot) {
//   module.hot.accept(() => render(App))
// }
