// import { createStyleSheet, withStyles } from 'material-ui/styles'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import themeColors from './themeColors'

export const stylesheet = {
  themeColors: themeColors,
  flex: {
    flex: 1
  },

  app: {
    title: {
      fontWeight: 200,
      fontSize: '1.5em',
      letterSpacing: '-1px'
    }
  },

  headline: {
    fontWeight: 400
  },

  errorPanel: {
    backgroundColor: themeColors.error,
    color: themeColors.textColor,
    width: '100%',
    height: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  flexComponent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  authLink: {
    marginTop: '1rem',
    fontWeight: '400',
    fontSize: '0.8rem',
    display: 'inline-block',
    textDecoration: 'underline'
  },

  gallery: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },

  galleryList: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    width: '30rem'
  },

  lightText: {
    fontWeight: 100
  },

  rightButton: {
    position: 'absolute',
    right: 0,
    bottom: 0
  },

  articleItem: {
    marginBottom: '20px'
  },

  appBarMenu: {
    position: 'absolute',
    bottom: '1rem',
    left: '50px'
  },

  appBarActions: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%'
  },

  appBarMenuItem: {
    padding: '1rem',
    fontWeight: 200,
    fontSize: '1rem',
    color: themeColors.white,
    opacity: 0.5,
    textTransform: 'capitalize'
  },

  appBarMenuItemActive: {
    color: themeColors.white,
    borderBottom: `3px solid ${themeColors.primary}`,
    opacity: 1
  },

  newsContainer: {
    position: 'relative',
    fontWeight: 200,
    lineHeight: '1.3em',
    fontSize: '1.1em'
  },

  newsDraft: {
    color: themeColors.error,
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 600,
    border: '2px solid',
    padding: '1px 5px',
    display: 'inline-block',
    marginRight: '5px',
    transform: 'translateY(-3px)'
  },

  newsHeader: {
    color: themeColors.textColor,
    padding: '0px 0 5px',
    margin: 0,
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.5em'
  },

  newsEditButton: {
    position: 'relative',
    color: themeColors.secondary
  },

  tags: {
    tagLabel: {
      textDecoration: 'underline',
      padding: '0.5rem 1rem',
      display: 'block'
    },
    tag: {
      transition: 'all 300ms ease-in-out'
    },
    active: {
      backgroundColor: themeColors.primary,
      color: themeColors.white
    }
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #979797',
    marginLeft: '20px',
    color: '#979797',
    padding: 0,
    textTransform: 'uppercase'
  },

  menuLabel: {
    borderBottom: `1px solid ${themeColors.secondary}`,
    color: themeColors.secondary,
    display: 'flex',
    fontSize: '0.8em',
    margin: '10px 20px',
    paddingBottom: '5px',
    textTransform: 'uppercase'
  },

  dates: {
    fontWeight: 400,
    fontSize: '0.7em',
    textTransform: 'uppercase',
    color: themeColors.secondary
  },

  editIcon: {
    position: 'absolute',
    top: '8px',
    left: '8px'
  },

  panel: {
    toolbar: {
      background: 'none',
      padding: '1.5em'
    },
    heading: {
      margin: '0',
      fontSize: '1em',
      textTransform: 'uppercase',
      fontWeight: '200'
    },
    container: {
      marginBottom: '20px'
    }
  },

  files: {
    icon: size => {
      return {
        width: `${size}px`,
        minWidth: `${size}px`,
        flex: 1,
        flexGrow: 0
      }
    },
    folder: {
      display: 'flex',
      padding: '5px',
      alignItems: 'center',
      cursor: 'pointer'
    },
    homeIcon: {
      width: '20px',
      cursor: 'pointer',
      color: '#787878',
      flex: '1',
      flexGrow: '0',
      minWidth: '20px'
    },
    content: {
      fontSize: '1.1rem',
      fontWeight: '200'
    },
    noFiles: {
      display: 'block',
      padding: '1em',
      textAlign: 'center',
      color: themeColors.secondaryColor,
      textTransform: 'uppercase'
    },
    folderActive: {
      background: '#FFFFCC',
      margin: '5px',
      width: '100px',
      height: '150px'
    },
    breadcrumbs: {
      background: '#F6f6f6',
      padding: '3px',
      fontSize: '0.9rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      fontWeight: '100',
      color: '#787878'
    },
    currentPath: {
      paddingLeft: '10px',
      flex: '1'
    },
    iconsManager: {
      display: 'flex',
      flexDirection: 'row',
      padding: '1rem',
      justifyContent: 'space-between'
    }
  }
}

// Customize theme
export const muiTheme = getMuiTheme({
  palette: {
    primary1Color: themeColors.appBar,
    primary2Color: themeColors.secondary
  },

  appBar: {
    height: 200,
    textColor: themeColors.white
  },

  raisedButton: {
    primaryColor: themeColors.primary,
    primaryTextColor: '#fff',
    secondaryColor: themeColors.secondaryColor,
    secondaryTextColor: '#fff',
    canvasColor: '#fff'
  },

  typography: {
    headline: stylesheet.headline
  }
})
