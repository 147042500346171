import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Page from 'components/Page'
import Panel from 'components/Panel'
import { bindActionCreators } from 'redux'
import { updateUser } from 'actions/authActions'
import { reduxForm } from 'redux-form'
import RaisedButton from 'material-ui/RaisedButton'
import InputField from 'components/InputField'


let ProfileForm = ({ handleSubmit, submitting, error, intl: { formatMessage } }) => (
  <div>
    <div>
      <InputField
        name="displayName"
        disabled={true}
        type="text"
        label={formatMessage({ id: 'auth_display_name'})}
        />
    </div>
    <div>
      <InputField
        name="email"
        disabled={true}
        type="email"
        label={formatMessage({ id: 'auth_email'})}
        />
    </div>

    {/* <div>
      <InputField
        name="phoneNumber"
        disabled={true}
        type="text"
        label={formatMessage({ id: 'auth_phone_number'})}
        />
    </div> */}

    <div>
      <InputField
        name="password"
        type="password"
        label={formatMessage({ id: 'auth_password'})}
        />
    </div>
    <div>
      <InputField
        name="confirmPassword"
        type="password"
        label={formatMessage({ id: 'auth_password_confirm'})}
        />
    </div>
    <RaisedButton primary={true} type="submit" onClick={handleSubmit} disabled={submitting}><FormattedMessage id="submit" /></RaisedButton>
  </div>
)

ProfileForm = reduxForm({
  form: 'profileForm',
})(injectIntl(ProfileForm))

class ProfileContainer extends React.Component {
  onSubmit = profile => {
    const { auth: { user }, updateUser } = this.props
    updateUser(user, profile)
  }

  render() {
    const { auth, form } = this.props

    if (auth.updated && form.values) {
      form.values.password = null
      form.values.confirmPassword = null
    }
    return (
      <Page title={<FormattedMessage id="page_title_profile" />}>
        <Panel style={{padding: '20px'}}>
          {auth.error && <strong style={{ color: '#DD0000'}}><FormattedMessage id={auth.error.code || auth.error} /></strong>}
          {auth.updated && <strong style={{ color: '#0D967F'}}><FormattedMessage id='auth_profile_updated' /></strong>}
          <ProfileForm
            initialValues={{
              email: auth.user.email,
              displayName: auth.user.displayName,
              // phoneNumber: auth.user.phoneNumber,
            }}
            onSubmit={this.onSubmit} />
        </Panel>
      </Page>
    )
  }
}



export default connect(
  state => ({
    auth: state.auth,
    form: state.form.profileForm,
  }),
  dispatch => ({
    updateUser: bindActionCreators(updateUser, dispatch),
  })
)(ProfileContainer)
