import React from 'react'
import { FormattedNumber } from 'react-intl'

const Currency = ({ value, currency = 'CRC' }) => (
  <span>
    <span>{`${currency === 'CRC' ? '₡' : '$'} `}</span>
    <FormattedNumber
      currency={currency}
      value={value}
      minimumFractionDigits={2}
      maximumFractionDigits={2}
    />
  </span>
)

export default Currency
