import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleDrawer } from '../actions/appActions'

import IconButton from 'material-ui/IconButton'

let ToggleDrawer = ({drawerOpen, toggleDrawer, wrapper = IconButton, children}) => {
  const Wrapper = wrapper || IconButton
  return (
    <Wrapper onClick={() => {toggleDrawer(!drawerOpen)}}>{children}</Wrapper>
  )
}

ToggleDrawer = connect(
  state => ({
    drawerOpen: state.app.drawerOpen,
  }),
  dispatch => ({
    toggleDrawer: bindActionCreators(toggleDrawer, dispatch)
  })
)(ToggleDrawer)

ToggleDrawer.propTypes = {
  drawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  wrapper: PropTypes.func,
  ...ToggleDrawer.propTypes,
}

export default ToggleDrawer
