import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  RaisedButton,
} from 'material-ui'
import InputField from '../InputField'

export default class InviteUserContainer extends React.Component {
  state = {
    error: null,
  }

  onSubmit = ({email}) => {
    const {
      handleClose,
      condo,
      property,
      inviteUser,
    } = this.props

    inviteUser(condo, property, email, _ => {
      handleClose()
    })
  }

  render() {
    const { handleClose } = this.props
    const { error } = this.state
    return (
      <InviteUserForm formError={error} onSubmit={this.onSubmit} handleClose={handleClose} />
    )
  }
}

const InviteUser = ({formError, handleClose, handleSubmit, submitting, intl: {formatMessage}}) => (
  <form onSubmit={handleSubmit}>
    {formError && <strong>{formError}</strong>}
    <div>
      <InputField
        name="email"
        type="email"
        label={formatMessage({id: 'auth_email'})}
        />
    </div>
    <RaisedButton type="submit" disabled={submitting} primary={true} style={{ marginRight: '1rem'}}><FormattedMessage id="invite" /></RaisedButton>
    <RaisedButton type="button" onClick={_ => handleClose()} secondary={true} buttonStyle={{ color: '#FFFFFF' }} disabled={submitting}><FormattedMessage id="cancel" /></RaisedButton>
  </form>
)

const InviteUserForm = reduxForm({
  form: 'InviteUser'
})(injectIntl(InviteUser))
