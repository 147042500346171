const themeColors = {
   primary: '#F6CA38',
   secondary: '#CFCFCF',
   secondaryColor: '#4A4A4A',
   textColor: '#4A4A4A',
   error: '#D0021B',
   white: '#FFFFFF',
   gray: '#676767',
   bg: '#F6F6F6',
   appBar: '#313638',
}

 export default themeColors
