import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link, Redirect } from 'react-router-dom'
import _pullAt from 'lodash/pullAt'
// import MediaQuery from 'react-responsive'

import {
  Table,
  TableBody,
  TableRowColumn,
  TableHeader,
  TableRow,
  IconButton,
  RaisedButton
} from 'material-ui'

import IconEye from 'material-ui-icons/RemoveRedEye'
import IconMoney from 'material-ui-icons/AccountBalance'
import Loading from 'components/Loading'

import { withCondoAdmin } from 'utils/IsCondoAdmin'
import { withRedux } from 'utils'
import { updateStatements } from 'actions/properties'

const style = {
  buttons: { margin: 5 },
  buttonLabel: { paddingLeft: 10, paddingRight: 10 }
}

class PropertyListContainer extends React.Component {
  state = {
    selected: []
  }

  selectRow = value => {
    this.setState({ selected: value === 'all' ? ['all'] : value })
  }

  render() {
    const {
      properties: { items, loading },
      condo,
      height = '1000',
      isCondoAdmin,
      updateStatements
    } = this.props

    const { selected } = this.state

    if (!items || !condo) {
      return null
    }

    if (loading) {
      return <Loading />
    }

    if (items && Object.keys(items).length === 1) {
      return (
        <Redirect
          to={`/condo/${condo.slug}/properties/${Object.keys(items)[0]}`}
        />
      )
    }

    let rows = Object.keys(items).map(key => ({
      key,
      ...items[key]
    }))

    const doAction = action => {
      const _rows = selected.includes('all') ? rows : _pullAt(rows, selected)

      if (_rows.length === 0) {
        console.warn('Nothing to do')
        return
      }

      updateStatements(
        condo,
        _rows.reduce((ret, i) => {
          ret[i.key] = ret
          return ret
        }, {}),
        action,
        true
      ).then(result => {
        console.log('All done generating statements', result)
        this.setState({ selected: [] })
      })
    }

    return (
      <div>
        {isCondoAdmin && (
          <div style={{ textAlign: 'right', margin: '0 0 1rem 0' }}>
            <RaisedButton
              style={style.buttons}
              labelStyle={style.buttonLabel}
              primary={true}
              disabled={selected.length === 0}
              onClick={() => doAction('generate')}
            >
              <FormattedMessage id="statement_generate" />
            </RaisedButton>
            <RaisedButton
              style={style.buttons}
              labelStyle={style.buttonLabel}
              onClick={() => doAction('send')}
              disabled={selected.length === 0}
              primary={true}
            >
              <FormattedMessage id="statement_generate_send" />
            </RaisedButton>
          </div>
        )}
        <Table
          height={`${height}px`}
          selectable={isCondoAdmin}
          multiSelectable={true}
          onRowSelection={isCondoAdmin ? this.selectRow : undefined}
        >
          <TableHeader displaySelectAll={isCondoAdmin}>
            <TableRow>
              <TableRowColumn>
                <FormattedMessage id="property" />
              </TableRowColumn>
              {/* <MediaQuery query="(min-width: 480px)"> */}
              <TableRowColumn>
                <FormattedMessage id="property_id" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="property_registry" />
              </TableRowColumn>
              <TableRowColumn>
                <FormattedMessage id="property_registry_id" />
              </TableRowColumn>
              {/* </MediaQuery> */}
              <TableRowColumn style={{ width: '50px' }} />
              <TableRowColumn style={{ width: '50px' }} />
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={isCondoAdmin}
            deselectOnClickaway={false}
          >
            {rows.map((item, idx) => (
              <TableRow
                selectable={isCondoAdmin}
                key={item.key}
                selected={selected.includes(idx) || selected.includes('all')}
              >
                <TableRowColumn>{item.name}</TableRowColumn>
                {/* <MediaQuery query="(min-width: 480px)"> */}
                <TableRowColumn>{item.propertyId}</TableRowColumn>
                <TableRowColumn>{item.registry}</TableRowColumn>
                <TableRowColumn>{item.registryId}</TableRowColumn>
                {/* </MediaQuery> */}
                <TableRowColumn style={{ width: '50px' }}>
                  <Link
                    to={`/condo/${condo.slug}/properties/${item.key}`}
                    key={item.key}
                  >
                    <IconButton>
                      <IconEye />
                    </IconButton>
                  </Link>
                </TableRowColumn>
                <TableRowColumn style={{ width: '50px' }}>
                  <Link
                    to={`/condo/${condo.slug}/statement/${item.key}`}
                    key={item.key}
                  >
                    <IconButton>
                      <IconMoney />
                    </IconButton>
                  </Link>
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export const PropertyList = withRedux(
  { properties: 'properties' },
  {
    updateStatements: updateStatements
  }
)(withCondoAdmin(PropertyListContainer))
