import { database, auth } from 'utils/firebase'

export const setArticle = article => {
  return dispatch => {
    dispatch({
      type: "ARTICLE_SET",
      article,
    })
  }
}

export const saveArticle = ({condoId, articleId, ...article}) => {
  return dispatch => {
    if (!condoId) {
      return dispatch({
        type: "ARTICLE_ERROR",
        error: {
          code: 'error_invalid_parameters',
        },
      })
    }

    if (!auth.currentUser.uid) {
      return dispatch({
        type: "ARTICLE_ERROR",
        error: {
          code: 'error_unauthorized',
        },
      })
    }
    const ref = database.ref(`articles/${condoId}/queue`).push()

    ref
      .set({
        ...article,
        by: auth.currentUser.uid,
        status: 'pending',
      })
      .then(args => ({
        type: "ARTICLE_SAVED",
        article,
      }))
      .catch(error => ({
        type: "ARTICLE_ERROR",
        error,
      }))
      .then(dispatch)
  }
}
