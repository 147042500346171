import { auth } from 'utils/firebase'
import { getLocale } from 'utils/locale'

function _authenticate(user) {
  return {
    type: "AUTH_SIGNIN",
    user,
  }
}

function _error(error) {
  return {
    type: "AUTH_ERROR",
    error,
  }
}

function _signout() {
  return {
    type: "AUTH_LOGOUT",
  }
}

export const start = () => {
  return dispatch => {
    auth.onAuthStateChanged(user => {
      if (user) {
        dispatch(_authenticate(user))
      }
      dispatch({ type: "AUTH_LOADED" })
    })
  }
}

export const signout = () => {
  return dispatch => {
    auth
      .signOut()
      .then(_signout)
      .catch(_error)
      .then(dispatch)
  }
}

export const signin = ({email, password}) => {
  return dispatch => {
    dispatch({
      type: "AUTH_LOADING"
    })

    auth
      .signInWithEmailAndPassword(email, password)
      .then(_authenticate)
      .catch(_error)
      .then(dispatch)
  }
}

export const signup = ({email, password}) => {
  return dispatch => {
    dispatch({
      type: "AUTH_LOADING"
    })

    auth
      .createUserWithEmailAndPassword(email, password)
      .then(_authenticate)
      .catch(_error)
      .then(dispatch)
  }
}

export const acceptInvitation = ({email, password}) => {
  return dispatch => {
    dispatch({
      type: "AUTH_LOADING"
    })
  }
}

export const sendPasswordResetEmail = (email) => {
  return dispatch => {
    auth.sendPasswordResetEmail(email)
      .then(() => ({
        type: "AUTH_PASSWORD_RESET",
      }))
      .catch(_error)
      .then(dispatch)
  }
}

export const updateUser = (user, profile) => {
  return dispatch => {
    dispatch({
      type: "AUTH_LOADING"
    })

    const {
      displayName,
      phoneNumber,
      password,
      confirmPassword,
    } = profile

    if (password && password !== confirmPassword) {
      return dispatch(_error('passwords_do_not_match'))
    }

    const funcs = []
    if (displayName) {
      funcs.push(user.updateProfile({ displayName }))
    }

    if (phoneNumber) {
      funcs.push(user.updatePhoneNumber(phoneNumber))
    }

    if (password) {
      funcs.push(user.updatePassword(password))
    }

    Promise
      .all(funcs)
      .then(_ => ({
        type: "AUTH_PROFILE_UPDATED",
      }))
      .catch(_error)
      .then(dispatch)
    // Update user Profile
  }
}

export const resetPassword = user => {
  return dispatch => {
    dispatch({
      type: "AUTH_RESET_LOADING",
    })

    auth.languageCode = getLocale()

    auth.sendPasswordResetEmail(user.email)
      .then(() => ({
        type: "AUTH_RESET_SUCCESS"
      }))
      .catch(error => ({
        type: "AUTH_RESET_ERROR",
        error,
      }))
      .then(dispatch)
  }
}
