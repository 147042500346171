import firebase from 'firebase'
import { firebase as fbConfig } from 'micondo-config'

firebase.initializeApp(fbConfig)

const auth = firebase.auth()
const database = firebase.database()
const storage = (firebase.storage || function() {})()

export { auth, database, storage }
