import React from 'react'
import { Field } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import Dropzone from 'react-dropzone'
import { storage } from 'utils/firebase'

import IconDelete from 'material-ui-icons/Delete'

const GalleryImage = ({file, onClick}) => (
  <div>
    <img src={file.preview || file} alt="" style={{
      maxWidth: '100px',
      maxHeight: '140px',
      marginRight: '5px',
    }} />
    {!file.preview && <IconDelete onClick={() => { onClick(file) }}/>}
    {file.preview && <small>Uploading ...</small>}
  </div>
)

class GalleryField extends React.Component {
  state = {
    files: [],
    newFiles: [],
  }

  onDrop = selectedFiles => {
    const { onChange } = this.props.input
    const { files } = this.state

    this.setState({
      newFiles: selectedFiles,
    })

    Promise.all(selectedFiles.map(file => {
      const ref = storage.ref(`uploads/articles/${Date.now()}-${file.name}`)
      return ref.put(file)
    }))
    .then(snapshots => {
      return snapshots.map(snapshot => snapshot.metadata.downloadURLs[0])
    })
    .then(urls => {
      const newFiles = [...files, ...urls]
      // Update Field value
      onChange(newFiles)

      this.setState({
        newFiles: [],
        files: newFiles,
      })
    })
  }

  removeImage = file => {
    const { files, newFiles } = this.state
    const { onChange } = this.props.input

    const joined = [...files, ...newFiles]

    const removed = joined.filter(url => url !== file)

    // TODO: Get firebase ref for this file and delete it from
    // there as well.
    // https://firebase.google.com/docs/reference/js/firebase.storage.Storage#refFromURL
    const ref = storage.refFromURL(file)
    ref.delete()
      .then(_ => {
        this.setState({
          files: removed,
        })
        onChange(removed)
      })
      .catch(console.log)
  }

  componentDidMount() {
    if (this.props.input && this.props.input.value) {
      this.setState({
        files: this.props.input.value
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.input.value !== nextProps.input.value) {
      this.setState({
        files: nextProps.input.value
      })
    }
  }

  render() {
    const { label } = this.props
    const { files, newFiles } = this.state
    const joined = [...files, ...newFiles]

    const style = {
      drop: {
        width: '200px',
        height: '200px',
        borderWidth: '4px',
        borderColor: 'rgb(228, 226, 226)',
        borderStyle: 'dashed',
        display: 'flex',
        alignItems: 'center',
        color: 'rgb(228, 226, 226)',
      },
      gallery: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        margin: '20px 0',
      },
      title: {
        borderBottom: '1px solid rgb(228, 226, 226)',
        margin: '30px 0',
        padding: '10px 0',
        color: 'rgb(126, 126, 126)',
      }
    }

    return (
      <div>
        <div style={style.gallery}>
          <label style={style.title}>{label}</label>
          {joined.length > 0 && joined.map((file, i) => (
            <GalleryImage file={file} key={i} onClick={this.removeImage} />
          ))}
        </div>
        <Dropzone style={style.drop} onDrop={this.onDrop} accept="image/*">
          <div><FormattedMessage id="drag_and_drop" /></div>
        </Dropzone>
      </div>
    )
  }
}

export default ({name, label}) => (
  <Field
    component={GalleryField}
    name={name}
    label={label}
    />
)
