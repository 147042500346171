import React from 'react'
import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import Page from 'components/Page'

// import { creator } from '../../actions/action'

class SettingsContainer extends React.Component {
  render() {
    return (
      <Page title={<FormattedMessage id='manage_settings' />}>

      </Page>
    )
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  // dispatch => ({
  //   creator: bindActionCreators(creator, dispatch),
  // })
)(SettingsContainer)
