import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sendPasswordResetEmail } from 'actions/authActions'
import { FormattedMessage } from 'react-intl'

class RecoverPasswordContainer extends React.Component {
  state = {
    email: null,
  }

  send = () => {
    const { send } = this.props
    const { email } = this.state

    send(email)
  }

  setEmail = e => {
    this.setState({ email: e.target.value });
  }

  render() {
    const { auth } = this.props

    return (
      <div>
        <h1>Recover Password</h1>
        <div>
          <input type="email" onChange={this.setEmail} placeholder="Email" />
          <button onClick={this.send}>Submit</button>
        </div>
        {auth.passwordReset && <strong><FormattedMessage id="auth_password_recover" /></strong>}
        {auth.error && <strong><FormattedMessage id={auth.error.code} /></strong>}
      </div>
    )
  }
}


export class ResetPassword extends React.Component {
  render() {
    return (
      <div>ResetPassword</div>
    )
  }
}

export const RecoverPassword = connect(
  state => ({
    auth: state.auth,
  }),
  dispatch => ({
    send: bindActionCreators(sendPasswordResetEmail, dispatch),
  }),
)(RecoverPasswordContainer)
