import React from 'react'
import { connect } from 'react-redux'
import { isCondoAdmin } from 'selectors/condos'

export const withCondoAdmin = WrappedComponent => {
  return connect(state => ({
    isCondoAdmin: isCondoAdmin(state),
  }))(WrappedComponent)
}
/**
 * Container component that hides if the user is not an admin in the condo
 * @type {[type]}
 * @prop condo  The condo ID to load
 */
class IsCondoAdminContainer extends React.Component {
  render() {
    const { user, isAdmin, otherwise, children } = this.props

    const Otherwise = otherwise || (<span></span>)
    // If `auth` and `isAdmin` aren't loaded then do nothing
    if (!user || !isAdmin) {
      return Otherwise
    }

    if (!children) {
      return null
    }

    return children
  }
}
export default connect(
  state => ({
    user: state.auth.user,
    isAdmin: isCondoAdmin(state)
  })
)(IsCondoAdminContainer)
